import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import close from '../Assets/close.svg';
import avator2 from '../Assets/logop.svg';

const CompaniesForm = ({ closeModal, editMode, companyData, onConnectionUpdated, updateTableDataAdd, setRows }) => {
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [gradientHeight, setGradientHeight] = useState('');

  useEffect(() => {
    const updateGradientHeight = () => {
      const height = window.innerWidth < 640 ? '13.7%' : '17.5%';
      setGradientHeight(height);
    };
    window.addEventListener('resize', updateGradientHeight);
    updateGradientHeight();
    return () => {
      window.removeEventListener('resize', updateGradientHeight);
    };
  }, []);

  const [formData, setFormData] = useState({
    company: '',
    RegistrationNumber: '',
    Website: '',
    ContactEmail: '',
    ContactNumber: '',
    Address: '',
    AddCompanyLogo: null,
    country:''
  });

  const validateForm = () => {
    const errors = {};
    if (!formData.RegistrationNumber?.trim()) errors.RegistrationNumber = 'Registration Number is required.';
    // if (!formData.Website?.trim()) errors.Website = 'Website is required.';
    if (!formData.company?.trim()) errors.company = 'Company is required.';
    if (!formData.Address?.trim()) errors.Address = 'Address is required.';
    if (!formData.country?.trim()) errors.country = 'Country is required.';
    if (!formData.ContactNumber?.trim()) errors.ContactNumber = 'Phone Number is required.';

    if (!formData.ContactEmail || !formData.ContactEmail.includes('@')) {
      errors.ContactEmail = 'Valid Contact Email is required.';
    }

    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };

  useEffect(() => {
    if (editMode && companyData) {
      setFormData({
        company_ID: companyData.company_ID,
        company: companyData.company,
        ContactEmail:companyData.ContactEmail,
        ContactNumber:companyData.ContactNumber,
        RegistrationNumber: companyData.RegistrationNumber,
        Address:companyData.Address,
        Website: companyData.Website,
        AddCompanyLogo: companyData.AddCompanyLogo || avator2,
        country:companyData.country
      });
    } else {
      setFormData({
        "company": "",
        "ContactEmail":"",
        "ContactNumber":"",
        "RegistrationNumber": "",
        "Address":"",
        "Website": '',
      });
    }
  }, [companyData, editMode]);

  // const handleDateChange = (date) => {
  //   setFormData((prevData) => ({
  //     ...prevData,
  //     installationTimestamp: date,
  //   }));
  // };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;
    if (type === 'file') {
      const reader = new FileReader();
      reader.onload = (upload) => {
        setFormData(prevData => ({
          ...prevData,
          [name]: upload.target.result 
        }));
      };
      reader.readAsDataURL(files[0]);
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
    
  };
  
  const handleAddCompany = async () => {
    try {
      console.log('Sending data to API:', formData);
      const response = await fetch('https://eunitstest.onrender.com/api/addcompanydetails', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.status === 200) {
        setRegistrationStatus('success');

        const data = await response.json();
        console.log('Response from server:', data);

        const newCompany = data.result;
        console.log(newCompany);
  
        updateTableDataAdd(newCompany);
        console.log('User added successfully!');
        
      } else {
        setRegistrationStatus('failure');
        console.error('Failed to add company to database');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleUpdateCompany = async () => {
    try {
      let apiUrl = 'https://eunitstest.onrender.com/api/updatecompanydetails';
      const response = await fetch(apiUrl, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });
  
      if (response.ok) {
        const data = await response.json();
        if (data.msg === 'Company details updated') {
          onConnectionUpdated(formData);
          setUpdateStatus('success');
          console.log('company details updated successfully!');
          
        } else {
          setUpdateStatus('failure');
          console.error('Failed to update company details', data.msg);
        }
      } else {
        console.error('Failed to update company details. HTTP status:', response.status);
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };
  
  const handleSave = async (e) => {
    e.preventDefault();
    try {

      if (!validateForm()) {
        console.error('Validation failed.');
        return;
      }

      if (editMode) {
        console.log('Updating company with ID:', formData.company_ID);
        await handleUpdateCompany();
      } else {
        await handleAddCompany();
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (
    <div className="fixed inset-0 flex md:items-center items-start pt-14 justify-center bg-gray-500 bg-opacity-75 overflow-y-auto">
      <div 
        className="max-w-sm md:max-w-xl p-6 pt-5"
        style={{
          background: `linear-gradient(to bottom, #F3F4F6 ${gradientHeight}, #ffffff ${gradientHeight}, #ffffff 100%)`,
          borderBottom: '1px solid black'
        }}
      >
        <div className="flex flex-row gap-2 items-start justify-between pb-12">
          <div className='flex flex-col'>
            <h2 className="font-semibold" style={{fontSize:'30px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>{editMode ? 'Update company' : 'New company'}</h2>
            <p className='text-gray-600 pt-1' style={{fontSize:'16px', color:'#010808', lineHeight:'24px', letterSpacing:'0%'}}>{editMode ? 'Update company By changing the information' : 'Add your new Company by Entering the information'}</p>
          </div>
          <img
            onClick={closeModal}
            src={close}
            alt="User"
            className="cursor-pointer mt-4"
          />
        </div>

        {registrationStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 rounded mb-4">
            Registration successful!
          </div>
        )}

        {registrationStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 rounded mb-4">
            Registration failed. Please try again
          </div>
        )}

        {updateStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 rounded mb-4">
            Update successful!
          </div>
        )}

        {updateStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 rounded mb-4">
            Update failed. Please try again.
          </div>
        )}
        
        <form>
          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
              <label htmlFor="macAddress" className=" text-gray-600">
                Company Name <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="company"
                name="company"
                placeholder="None"
                value={formData.company}
                onChange={handleChange}
                className={`${formErrors.company ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />
                {formErrors.company && (
                  <p className="text-red-500 text-xs italic">{formErrors.company}</p>
                )}
            </div>

            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label htmlFor="country" className="text-gray-600">
                Country <span className="text-red-500">*</span>
              </label>
              <select
                id="country"
                name="country"
                
                value={formData.country}
                
                
                onChange={handleChange}
                className={`${formErrors.country ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
              >
                <option value="">Select a country</option>
                <option value="IS">Iceland (IS)</option>
                <option value="US">United States (US)</option>
                <option value="IN">India (IN)</option>
                <option value="ET">Ethiopia (ET)</option>
                <option value="RS">Serbia (RS)</option>
              </select>

              {formErrors.country && (
                <p className="text-red-500 text-xs italic">{formErrors.country}</p>
              )}
          </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
              <label htmlFor="hardwareDetails" className=" text-gray-600">
                Registration Number <span className="text-red-500">*</span>
              </label>
              <input
                id="text"
                name="RegistrationNumber"
                placeholder="None"
                value={formData.RegistrationNumber}
                onChange={handleChange}
                className={`${formErrors.RegistrationNumber ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />

                {formErrors.RegistrationNumber && (
                  <p className="text-red-500 text-xs italic">{formErrors.RegistrationNumber}</p>
                )}
            </div>
            
            <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
              <label htmlFor="category" className=" text-gray-600">
                Website
              </label>
              <input
                id="Website"
                name="Website"
                placeholder="None"
                value={formData.Website}
                onChange={handleChange}
                className={`${formErrors.Website ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />

                {formErrors.Website && (
                  <p className="text-red-500 text-xs italic">{formErrors.Website}</p>
                )}
            </div>
          </div>

          <div className="flex flex-wrap -mx-3 mb-4">
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0">
              <label htmlFor="location" className=" text-gray-600">
                Contact Email <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="ContactEmail"
                name="ContactEmail"
                placeholder="None"
                value={formData.ContactEmail}
                onChange={handleChange}
                className={`${formErrors.ContactEmail ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />

                {formErrors.ContactEmail && (
                  <p className="text-red-500 text-xs italic">{formErrors.ContactEmail}</p>
                )}
            </div>
            
            <div className="w-full md:w-1/2 px-3 mb-2 md:mb-0">
              <label htmlFor="area" className=" text-gray-600">
                Phone Number <span className="text-red-500">*</span>
              </label>
              <input
                type="text"
                id="ContactNumber"
                name="ContactNumber"
                placeholder="None"
                value={formData.ContactNumber}
                onChange={handleChange}
                className={`${formErrors.ContactNumber ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />

                {formErrors.ContactNumber && (
                  <p className="text-red-500 text-xs italic">{formErrors.ContactNumber}</p>
                )}
            </div>
          </div>

          <div className="flex flex-wrap -mx-3">
            <div className="w-full px-3 mb-6">
              <label htmlFor="description" className=" text-gray-600">
                Address <span className="text-red-500">*</span>
              </label>
              <input
                id=" Address"
                name="Address"
                placeholder="None"
                value={formData.Address}
                onChange={handleChange}
                className={`${formErrors.Address ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />

                {formErrors.Address && (
                  <p className="text-red-500 text-xs italic">{formErrors.Address}</p>
                )}
            </div>
          </div>

          <div className="mb-4">
            <label className="text-gray-600">
              Add Company Logo
            </label>
            <div className="flex items-center mt-2">
              {formData.AddCompanyLogo && (
                  <img
                    src={formData.AddCompanyLogo}
                    alt="Company Logo"
                    className="shadow-md mr-4 h-12 w-20 object-contain"
                  />
                )}
              <input
                type="file"
                id="AddCompanyLogo"
                name="AddCompanyLogo"  
                placeholder="Click to upload or Drag and Drop"
                accept="image/*"
                onChange={handleChange}
                className="w-full px-24 md:px-44 py-6 border focus:outline-none"
              />
            </div>
          </div>

          <div className="flex md:flex-row md:justify-end flex-col pt-6 gap-3">
            <button
              onClick={closeModal}
              className="px-3 py-2 text-gray-600  border focus:outline-none focus:ring focus:border-gray-300"
            >
              Discard
            </button>
            <button
              onClick={handleSave}
              type="submit"
              className={`py-2 px-3 text-white bg-black hover:bg-teal-600 focus:outline-none focus:ring focus:border-teal-300}`}
            >
              {editMode ? 'Save changes' : 'Add company'}
            </button>

          </div>
        </form>
      </div>
    </div>
  );
};

export default CompaniesForm;
