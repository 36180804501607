import React, { useEffect, useState } from 'react';
import { useSwipeable } from 'react-swipeable';
import axios from 'axios';
import Table from './TableEvent';
import { CSVLink } from 'react-csv';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import icon from '../Assets/iconS.svg';
import download from '../Assets/download.svg';
import search from '../Assets/search.svg';
import './styles.css';
import { useLocation } from 'react-router-dom';

const Events = ({setIsMenuOpen}) => {
  const [filter, setFilter] = useState('');
  const location = useLocation();
  const [result, setResult] = useState([]);
  const [rows, setRows] = useState([]);
  const headings = ['timestamp','username', 'code', 'entryway','venue', 'company_name', 'status'];
  const [data, setData] = useState();

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []); 

  useEffect(() => {
    fetchData();
  }, [filter]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterFromQuery = searchParams.get('filter');
    const codeFromUrl = location.pathname.split('/').pop();
    const filterParam = searchParams.get('filter');
    if (filterFromQuery) {
        setFilter(filterFromQuery);
    } else if (codeFromUrl && codeFromUrl !== 'events' && !filterParam) {
        setFilter(codeFromUrl);
        console.log('Filter from URL code:', codeFromUrl);
    } else {
        setFilter('');
        console.log('Filter reset to empty');
    }
    fetchData();
  }, [location]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterFromQuery = searchParams.get('filter');
    if (filterFromQuery) {
      setFilter(filterFromQuery);
      console.log('Filter set from query:', filterFromQuery);
    }
    fetchData();
  }, [location.search]);

  const fetchData = async () => {
    try {
      const response = await axios.get('https://eunitstest.onrender.com/api/geteventdetails');
      console.log('Original data:', response.data);
      let data = response.data.map(event => {
        let status = event.status;
        if (status === null || status === 'false' || status === 'False' || status === 'Fail' || status === 'fail' || status === 'connection') {
          status = 'Fail';
        } else if (status.startsWith('Fail-')) {
          status = status.replace(/Fail-/g, ' ');
          if (['True', 'success', 'Success'].includes(status)) {
            status = 'Success';
          }
        }
        return { ...event, status };
      });
  
      data.sort((a, b) => {
        const valueA = new Date(a.timestamp);
        const valueB = new Date(b.timestamp);
        return valueB - valueA;
      });
      setResult(data);
      if (filter === 'Last24Hours') {
        const now = new Date();
        const last24Hours = new Date(now.getTime() - (24 * 60 * 60 * 1000));
        data = data.filter(event => {
          const eventTime = new Date(event.timestamp);
          return eventTime >= last24Hours;
        });
        setResult(data);
      } else if (filter) {
        const filteredData = applyFilter(data, filter, headings);
        setResult(filteredData);
      }
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  
  const applyFilter = (data, filter, headings) => {
    if (filter.length >= 3) {
      const filtered = data.filter((row) => {
        const valuesToFilter = headings.map((heading) => {
          const rowValue = String(row[heading] || '').toLowerCase();
          return rowValue.includes(filter.toLowerCase());
        });
        return valuesToFilter.some((filteredValue) => filteredValue);
      });
      return filtered;
    } else {
      return data;
    }
  };
  
  useEffect(() => {
    const applyFilter = () => {
      if (filter.length >= 3) {
        const filtered = rows.filter((row) => row.join(' ').includes(filter));
        setResult(filtered);
      } else {
        setResult(rows);
      }
    };
    applyFilter();
  }, [filter, rows]);

  const handleDelete = async (eventId) => {
    try {
      const response = await axios.post('https://eunitstest.onrender.com/api/deleteeventdetails', { eventId });
      if (response.status === 200) {
        console.log('Delete successful:', response.data);

        const updatedResults = result.filter(event => event.eventId !== eventId);
        const filteredData = applyFilter(updatedResults, filter, headings);
        setResult(filteredData);
        setRows(filteredData);
        setData(filteredData);
  
        if (data) {
          const updatedData = data.filter(event => event.eventId !== eventId);
          setResult(updatedData);
          console.log('Updated data:', updatedData);
        }
      } else {
        console.error('Failed to delete event:', response.data.error);
      }
    } catch (error) {
      console.error('Error while deleting event:', error);
    }
  };
  
  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });

  return (
    <div className="mx-16 pt-0 md:pt-8">
      <div {...handlers} className="pr-8 pl-8">
        <div className='text-black md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden'>
        <img
          src={icon}
          alt="User"
          className=""
        />
        <FontAwesomeIcon icon={faChevronRight} className="" />
          <span className='font-bold'>Past events</span>
        </div>
        <div className="bg-gray-100 text-black flex flex-col items-start md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col pb-6 md:pt-0">
            <span className="font-bold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>Events</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>View any events related to your account</p>
          </div>
          <div className="flex flex-col items-start mt-6 md:items-end">
            <div className="bg-white" >
              <CSVLink
                data={result}
                headers={headings}
                filename={'devices.csv'}
                className="custom-width flex items-center justify-center gap-2 border bg-white border-gray-300 md:px-3 py-2" style={{color:"#010808"}}
              >
                <img
                  src={download}
                  alt="User"
                  className=""
                />
                <span className='font-bold' style={{fontSize:'18px', lineHeight:'28px',color:'#010808', letterSpacing:'0%'}}>Export</span>
              </CSVLink>
            </div>
            <div className='custom-width flex flex-col mt-6 md:mt-0'>
              <p className="text-black hidden lg:block md:block">Search</p>
              <div className="relative">
                <img
                  src={search}
                  alt="User"
                  className="absolute mt-5 pt-1 transform -translate-y-1/2 left-3 text-gray-500"
                />
                <input
                  className="px-12 md:px-10 w-full text-black py-2 border focus:outline-none"
                  style={{
                    outline: 'none',
                    '&:focus': {
                      borderColor: '#349999'
                    },
                  }}
                  type="text"
                  placeholder="Search by keyword"
                  value={filter}
                  onChange={(e) => setFilter(e.target.value)}
                />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className='mb-6 mt-6 ml-8 mr-8 overflow-x-auto'>
        <Table 
          headings={headings} 
          rows={result} 
          onDelete={handleDelete} 
          userData={userData} 
        />
      </div>
    </div>
  );
};

export default Events;
