import React, { useState, useEffect } from 'react';
import { useSwipeable } from 'react-swipeable';
import icon from '../Assets/iconS.svg';
import divider from '../Assets/Divider.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { faCheck } from '@fortawesome/free-solid-svg-icons';
import { checkPasswordCriteria } from './utils'; 

const Profile = ({setIsMenuOpen}) => {
  const [imagePreview, setImagePreview] = useState(null);
  const [updateStatus, setUpdateStatus] = useState(null);
  const [fileValid, setFileValid] = useState(true);
  const [errorMessage, setErrorMessage] = useState("");
  const [passwordMatch, setPasswordMatch] = useState(true);
  const [passwordStrength, setPasswordStrength] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [passwordStrengthError, setPasswordStrengthError] = useState("");
  const [passwordMatchError, setPasswordMatchError] = useState("");
  const [currentPasswordError, setCurrentPasswordError] = useState("");
  const [passwordCriteria, setPasswordCriteria] = useState({
    minLength: false,
    upperCase: false,
    lowerCase: false,
    numeric: false,
    specialChar: false,
  });
  const [roles, setRoles] = useState([]);
  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []); 

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const response = await fetch("https://eunitstest.onrender.com/api/getuser");
        if (response.ok) {
          const users = await response.json();
          let rolesOptions = Array.from(new Set(users.map(user => user.role)));
  
          const userDataFromStorage = localStorage.getItem('userData');
          if (userDataFromStorage) {
            const parsedUserData = JSON.parse(userDataFromStorage);
            setUserData(parsedUserData);
  
            let filteredRoles;
            if (parsedUserData.role === 'System Admin') {
              filteredRoles = rolesOptions;
            } else if (parsedUserData.role === 'Company Admin') {
              filteredRoles = rolesOptions.filter(role => role !== 'System Admin');
            } else if (parsedUserData.role === 'Company User') {
              filteredRoles = rolesOptions.filter(role => role === 'Company User');
            } else {
              filteredRoles = [];
            }
  
            setRoles(filteredRoles);
          }
        } else {
          throw new Error('Failed to fetch user data');
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
  
    fetchUserData();
  }, []);
  
  const [formData, setFormData] = useState({
    firstName: "",
    lastName: "",
    email: "",
    username: "",
    role: "",
    image: null,
    password: "",
    currentPassword: "",
    confirmPassword: "",
  });

  useEffect(() => {
    if (userData) {
      setFormData({
        firstName: userData.firstName,
        lastName: userData.lastName,
        image: userData.image,
        email:userData.email,
        username:userData.username,
        role:userData.role,
        password: "",
        currentPassword: "",
        confirmPassword: "",
      });
      setImagePreview(userData.image);
    }
  }, [userData]);

  const deleteProfilePicture = () => {
    setImagePreview(null);
    setFormData((prevData) => ({
      ...prevData,
      image: null,
    }));
  };

  const handleChange = (e) => {
    const { name, value, type, files } = e.target;

    if (type === 'file' && name === 'image') {
        const file = files[0];
        const img = new Image();
        img.src = URL.createObjectURL(file);
        img.onload = () => {
            if (img.height > 800) {
                setFileValid(false);
                setErrorMessage("Please upload a JPG/PNG/SVG file with a width and a height less than or equal to 800 pixels.");
                setImagePreview(null);
            } else {
                setFileValid(true);
                setErrorMessage("");
                const reader = new FileReader();
                reader.onload = () => {
                    setFormData(prevData => ({
                        ...prevData,
                        image: reader.result,
                    }));
                    setImagePreview(reader.result);
                };
                reader.readAsDataURL(file);
            }
        };
    } else {
        setFormData(prevData => ({
            ...prevData,
            [name]: value,
        }));

        if (name === 'password') {
            const criteria = checkPasswordCriteria(value);
            setPasswordCriteria(criteria);
            setPasswordStrength(checkPasswordStrength(value));
        }

        if (name === 'confirmPassword') {
            setPasswordMatch(value === formData.password);
        }
    }
  };
  
  const checkPasswordStrength = (password) => {
    const regex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@#$!%^&*])[A-Za-z\d@#$!%^&*]{8,15}$/;
    if (!password.match(regex)) {
      return "Weak";
    } else if (password.length > 10) {
      return "Strong";
    } else {
      return "Medium";
    }
  };

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const handleSave = async () => {
    setPasswordStrengthError("");
    setPasswordMatchError("");
    setCurrentPasswordError("");
  
    try {
      let dataToSend = { ...formData };
  
      if (formData.password) {
        if (passwordStrength !== "Strong") {
          setPasswordStrengthError("Password strength is not strong enough");
          return;
        }

        if (formData.password !== formData.confirmPassword) {
          setPasswordMatchError("Passwords do not match");
          return;
        }
  
        if (formData.currentPassword !== userData.password) {
          setCurrentPasswordError("Previous password does not match");
          return;
        }
      } else {
        dataToSend = {
          ...dataToSend,
          password: userData.password,
          currentPassword: userData.password,
          confirmPassword: userData.password,
          userId: userData.userId,
          company: userData.company,
          role:userData.role,
          username:userData.username
        };
      }
  
      console.log("Sending data to API:", dataToSend);
      const response = await fetch(
        "https://eunitstest.onrender.com/api/updateuser",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(dataToSend),
        }
      );
  
      if (response.status === 200) {
        setUpdateStatus("success");
        setTimeout(() => {
          setUpdateStatus(null);
        }, 3000);
      } else {
        setUpdateStatus("failure");
        setTimeout(() => {
          setUpdateStatus(null);
        }, 3000);
        
      }
    } catch (error) {
      console.error("Error:", error.message);
      setErrorMessage(error.message);
    }
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });

  return (
    <div {...handlers} className="mx-16 pt-0 md:pt-8 pr-8 pl-8">
      <div className='text-black md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden'>
        <img
          src={icon}
          alt="User"
          className=""
        />
        <FontAwesomeIcon icon={faChevronRight} className="" />
        <span className='font-bold'>Edit Profile</span>
      </div>

      <div className="bg-gray-100 pt-8 text-black flex flex-col items-start lg:flex-row lg:items-center lg:justify-between pb-8">
        <div className="flex flex-col mb-6">
          <span className="font-bold mr-auto" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>Edit Profile</span>
          <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>Update your photo and personal details in here</p>
        </div>
      </div>

        {updateStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 rounded mb-4">
            Update successful!
          </div>
        )}

        {updateStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 rounded mb-4">
            Update failed. Please try again.
          </div>
        )}

        {errorMessage && (
          <div className="bg-red-200 text-red-800 p-2 rounded mb-4">
            {errorMessage}
          </div>
        )}

    <div className='bg-white'>
      <div className='p-8'>
        <div className='flex flex-col md:grid md:grid-cols-2 md:gap-x-6 md:gap-y-4'>
          <div className="flex flex-col md:flex-row md:items-center mb-4 md:mb-0">
            <label className="text-gray-700 mb-2 md:mb-0 md:w-1/3">First Name</label>
            <input 
              type="text" 
              id="firstName" 
              placeholder='Enter Your First Name'
              name="firstName" 
              className="w-full md:w-2/3 border text-gray-600 px-2 py-2 focus:outline-none" 
              value={formData.firstName}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-col md:flex-row md:items-center mb-4 md:mb-0">
            <label className="text-gray-600 mb-2 md:mb-0 md:w-1/3">Last Name</label>
            <input 
              type="text" 
              id="lastName" 
              placeholder='Enter Your Last Name'
              name="lastName" 
              className="w-full md:w-2/3 border text-gray-600 px-2 py-2 focus:outline-none"
              value={formData.lastName}
              onChange={handleChange} 
              required
            />
          </div>
        </div>

        <div className='flex flex-col md:grid md:grid-cols-2 md:gap-x-6 md:gap-y-4 md:mt-4'>
          <div className="flex flex-col md:flex-row md:items-center mb-4 md:mb-0">
            <label className="text-gray-700 mb-2 md:mb-0 md:w-1/3">Email</label>
            <input 
              type="email" 
              id="email" 
              placeholder='Enter Your Email Address'
              name="email" 
              className="w-full md:w-2/3 border text-gray-600 px-2 py-2 focus:outline-none" 
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-col md:flex-row md:items-center">
            <label className="text-gray-700 mb-2 md:mb-0 md:w-1/3">User Name</label>
            <input 
              type="text" 
              id="Username" 
              placeholder='Enter Your User Name'
              name="username" 
              className="w-full md:w-2/3 border text-gray-600 px-2 py-2 focus:outline-none" 
              value={formData.username}
              onChange={handleChange}
              required
            />
          </div>

          <div className="flex flex-col md:flex-row md:items-center">
            <label className="text-gray-700 mb-2 md:mb-0 md:w-1/3">Role</label>
            <select
              name="role"
              className="w-full md:w-2/3 border text-gray-600 px-2 py-2 focus:outline-none"
              value={formData.role}
              onChange={handleChange}
              required
            >
              {roles.map(role => (
                <option key={role} value={role}>{role}</option>
              ))}
            </select>
          </div>
        </div>
          <img
            src={divider}
            alt="User"
            className="w-full mb-6 mt-6"
          />

        <div className="mr-4 flex flex-col md:items-center lg:flex-row md:flex-row gap-3">
          <div className='lg:flex lg:flex-col text-gray-600 pr-16'>
            <span className="text-gray-700 text-base font-semibold" style={{fontSize:'16px', lineHeight:'20px', letterSpacing:'0%'}}>Profile Picture</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>This will be displayed on your profile.</p>
          </div>

          {imagePreview && <img src={imagePreview} alt="Preview" className="shadow-md mr-8 h-16 w-16" />}
          {!fileValid && (
            <p className="text-red-500 py-5 text-md">{errorMessage}</p>
          )}

          <button
            onClick={deleteProfilePicture}
            className={`text-red-500 px-2 ${errorMessage ? 'py-0' : 'py-2'}`}
          >
            Delete
          </button>

          <input
            type="file"
            accept="image/*"
            name='image'
            onChange={handleChange}
            className="hidden"
            id="profileImageInput"
          />
          <label
            htmlFor="profileImageInput"
            className="cursor-pointer text-gray-600 px-2 py-2 pl-32 ml-2 md:pl-0 md:ml-0"
          >
            Upload
          </label>

          <span className="text-md text-gray-800 py-5">
            (Please upload a JPG/PNG file with dimensions 400x400 pixels)
          </span>
        </div>

        <div className='pt-12 text-gray-700'>
          <h2 className="font-bold" style={{fontSize:'20px', lineHeight:'28px', letterSpacing:'0%'}}>Password</h2>
          <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'20px', letterSpacing:'0%'}}>Please enter your current Password to change your Password</p>
          <img
            src={divider}
            alt="User"
            className="w-full mt-6"
          />
          
          <div className="mr-4 flex flex-col md:items-center lg:flex-row md:flex-row gap-2 lg:gap-52 md:gap-52 mb-6 pt-6">
            <label className="text-gray-700 text-base font-semibold" style={{fontSize:'16px', lineHeight:'20px', letterSpacing:'0%'}}>Current Password <span className="text-red-500">*</span></label>
            <div className='relative'>
              <input 
                type={showPassword ? 'text' : 'password'} 
                placeholder='Enter your current Password'
                name="currentPassword" 
                className={`w-full md:mr-44 border text-gray-600 px-2 py-2 focus:outline-none ${currentPasswordError ? 'border-red-500 w-full border text-gray-600 px-2 md:pr-44 py-2 focus:outline-none' : ''}`}
                value={formData.currentPassword}
                onChange={handleChange}
                onFocus={() => setPasswordStrengthError("")}
              />

                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="absolute right-3 top-4 cursor-pointer text-gray-500"
                  onClick={togglePasswordVisibility}
                />
            </div>

            {currentPasswordError && (
              <p className="text-red-500 mt-2">{currentPasswordError}</p>
            )}
          </div>
          
          <img
              src={divider}
              alt="User"
              className="w-full mb-6"
            />

          <div className="mr-4 flex flex-col md:items-center lg:flex-row md:flex-row gap-2  lg:gap-56 md:gap-56 mb-6 pt-6">
            <label className="text-gray-700 text-base font-semibold pr-2" style={{fontSize:'16px', lineHeight:'20px', letterSpacing:'0%'}}>New Password <span className="text-red-500">*</span></label>
            <div className='relative'>
              <input 
                name="password" 
                placeholder='Enter your new Password'
                className={`w-full md:mr-44 border text-gray-600 px-2 py-2 focus:outline-none ${passwordStrengthError ? 'border-red-500' : ''}`} 
                value={formData.password}
                onChange={handleChange}
                type={showPassword ? 'text' : 'password'}
              />

                <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="absolute right-3 top-4 cursor-pointer text-gray-500"
                  onClick={togglePasswordVisibility}
                />
            </div>

            {passwordStrengthError && (
              <p className="text-red-500 mt-2">{passwordStrengthError}</p>
            )}
          </div>

          {formData.password && (
            <div className='flex flex-column md:flex-row  md:justify-content-center items-center md:gap-48'>
              <div className="text-md">
              <div className="text-md">
                <span className="font-bold ">
                  Password Strength: 
                </span>
                <span className="font-bold text-md pl-2" style={{ color: passwordStrength === 'Weak' ? '#B42318' : passwordStrength === 'Strong' ? '#027A48' : 'orange' }}>
                  {passwordStrength}
                </span>
              </div>

              </div>
              <div>
                The Password contains:
                <ul className='pl-4 pb-2'>
                  <li>
                    <FontAwesomeIcon icon={faCheck} style={{ color: passwordCriteria.minLength ? '#027A48' : '#575757' }} /> 8 to 15 characters
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} style={{ color: passwordCriteria.upperCase ? '#027A48' : '#575757' }} /> At least one uppercase letter (A-Z)
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} style={{ color: passwordCriteria.lowerCase ? '#027A48' : '#575757' }} /> At least one lowercase letter (a-z)
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} style={{ color: passwordCriteria.numeric ? '#027A48' : '#575757' }} /> At least one number (0-9)
                  </li>
                  <li>
                    <FontAwesomeIcon icon={faCheck} style={{ color: passwordCriteria.specialChar ? '#027A48' : '#575757' }} /> At least one special character (#, $, @, ...)
                  </li>
                </ul>
          </div>
            </div>
          )}

          <img
              src={divider}
              alt="User"
              className="w-full mb-6"
            />

          <div className="mr-4 flex flex-col md:items-center lg:flex-row md:flex-row gap-2 lg:gap-52 md:gap-48 mb-6 pt-6">
            <label className="text-gray-700 text-base font-semibold" style={{fontSize:'16px', lineHeight:'20px', letterSpacing:'0%'}}>Confirm Password <span className="text-red-500">*</span></label>
            <div className='relative'>
              <input 
                type={showPassword ? 'text' : 'password'}
                placeholder='Confirm your new Password'
                name="confirmPassword" 
                className={`w-full md:mr-44 border text-gray-600 px-2 py-2 focus:outline-none ${!passwordMatch ? 'border-red-500' : ''}`} required
                value={formData.confirmPassword}
                onChange={handleChange}
              />

              <FontAwesomeIcon
                  icon={showPassword ? faEyeSlash : faEye}
                  className="absolute right-3 top-4 cursor-pointer text-gray-500"
                  onClick={togglePasswordVisibility}
                />
            </div>
            {passwordMatchError && (
            <p className="text-red-500 mt-2">{passwordMatchError}</p>
            )}

            {!passwordMatch && <p className="text-red-500">Passwords do not match</p>}
          </div>
        </div>
        </div>
        <div className="flex justify-end pt-6 gap-3 pr-6 bg-gray-100">
          <button
            className="border text-gray-600 px-4 py-2"
          >
            Discard
          </button>

          <button
            onClick={handleSave}
            className="bg-black border text-gray-100 px-4 py-2"
          >
            Save Changes
          </button>

        </div>
      </div>
    </div>
  );
};

export default Profile;
