import React, { useState, useEffect } from 'react';
import axios from 'axios';
import close from '../Assets/close.svg';
import icelandIcon from '../Assets/is.svg';
import franceIcon from '../Assets/et.svg';
import ukIcon from '../Assets/uk.svg';
import './styles.css';
import CustomDropdown from './CustomDropDown';
import { usePhoneNumberFormat } from './Provided';

const SettingsModal = ({ isOpen, closeModal, companyData, updateCompanyData, userData }) => {
  const [switchStates, setSwitchStates] = useState(false);
  const [smsLeadTime, setSmsLeadTime] = useState(0);
  const [phoneNumberLocale, setPhoneNumberLocale] = useState('IS');
  const [displayIntl, setDisplayIntl] = useState(false);
  const [successMessage, setSuccessMessage] = useState('');
  const [defaultMessage, setDefaultMessage] = useState('');
  const [smsSenderName, setSmsSenderName] = useState('');
  // const [lastName, setLastName] = useState('');
  const [formattedPhoneNumber, setFormattedPhoneNumber] = useState('');
  const [automaticMessage, setAutomaticMessage] = useState('');
  const { isInternational, toggleInternationalFormat } = usePhoneNumberFormat();
  const [gradientHeight, setGradientHeight] = useState('');
  const [automaticMessageCursor, setAutomaticMessageCursor] = useState(null);
  const [defaultMessageCursor, setDefaultMessageCursor] = useState(null);

  useEffect(() => {
    const updateGradientHeight = () => {
      const height = window.innerWidth < 640 ? '10.7%' : '12%';
      setGradientHeight(height);
    };
    window.addEventListener('resize', updateGradientHeight);
    updateGradientHeight();
    return () => {
      window.removeEventListener('resize', updateGradientHeight);
    };
  }, []);

  const localeOptions = [
    { value: 'IS', label: 'IS - Iceland', icon: icelandIcon },
    { value: 'US', label: 'US - United States', icon: ukIcon },
    { value: 'ET', label: 'ET - Ethiopia', icon: franceIcon },
  ];

  const replaceSpecialChars = (str) => {
    const charMap = {
      'ö': 'o', 'ä': 'a', 'ü': 'u', 'ß': 'ss',
      'þ': 'th',
      'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'å': 'a', 'æ': 'ae',
      'ç': 'c', 'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e',
      'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
      'ð': 'd', 'ñ': 'n',
      'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o',
      'ø': 'o', 'œ': 'oe',
      'ù': 'u', 'ú': 'u', 'û': 'u',
      'ý': 'y', 'ÿ': 'y',
      'ā': 'a', 'ă': 'a', 'ą': 'a', 'ć': 'c', 'č': 'c', 'ĉ': 'c', 'ċ': 'c', 
      'ď': 'd', 'đ': 'd', 'ē': 'e', 'ĕ': 'e', 'ė': 'e', 'ę': 'e', 'ě': 'e',
      'ğ': 'g', 'ġ': 'g', 'ģ': 'g', 'ħ': 'h', 'ĩ': 'i', 'ī': 'i', 'ĭ': 'i', 'į': 'i', 'ı': 'i',
      'ĳ': 'ij', 'ķ': 'k', 'ĸ': 'k', 'ĺ': 'l', 'ļ': 'l', 'ľ': 'l', 'ŀ': 'l', 'ł': 'l',
      'ń': 'n', 'ņ': 'n', 'ň': 'n', 'ŋ': 'n', 'ō': 'o', 'ŏ': 'o', 'ő': 'o',
      'ŕ': 'r', 'ŗ': 'r', 'ř': 'r', 'ś': 's', 'š': 's', 'ŝ': 's', 'ş': 's', 'ș': 's', 'ť': 't',
      'ţ': 't', 'ț': 't', 'ŧ': 't', 'ũ': 'u', 'ū': 'u', 'ŭ': 'u', 'ů': 'u', 'ű': 'u', 'ų': 'u',
      'ŵ': 'w', 'ŷ': 'y', 'ź': 'z', 'ż': 'z', 'ž': 'z',
      'Í': 'i',
      'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h',
      'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p',
      'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x',
      'y': 'y', 'z': 'z'
    };
    return str.split('').map(char => charMap[char] || char).join('');
  };

  useEffect(() => {
    if (companyData && userData) {
      setDefaultMessage(companyData.Body || `your access code to ${companyData.company} Open 8-23:00`);
      setSwitchStates(companyData.SmsActivity === "true");
      setSmsLeadTime(companyData.SmsLeadTime || 0);
      setPhoneNumberLocale(companyData.phoneNumberLocale || 'GB');
      setDisplayIntl(companyData.displayIntl === "true");
      setSmsSenderName(companyData.smsSenderName || companyData.company);
      setAutomaticMessage(companyData.AutomaticNotifiationSMS || 'your booking starts at {{booking time}} code is {{code}}');
      // setLastName(userData.lastName || '');
      formatPhoneNumber();
    }
  }, [companyData, userData]);

  useEffect(() => {
    formatPhoneNumber();
  }, [phoneNumberLocale, displayIntl, companyData.ContactNumber]);

  const insertShortcode = (shortcode, target) => {
    if (target === 'automaticMessage' && automaticMessageCursor !== null) {
      setAutomaticMessage(prev => 
        prev.substring(0, automaticMessageCursor) + shortcode + prev.substring(automaticMessageCursor)
      );
    } else if (target === 'defaultMessage' && defaultMessageCursor !== null) {
      setDefaultMessage(prev => 
        prev.substring(0, defaultMessageCursor) + shortcode + prev.substring(defaultMessageCursor)
      );
    }
  };
  

  const handleCursor = (event, setter) => {
    setter(event.target.selectionStart);
  };

  const formatPhoneNumber = () => {
    let exampleNumber = companyData.ContactNumber || '663 8555';
    let formattedBaseNumber = `${exampleNumber.slice(0, 3)} ${exampleNumber.slice(3)}`;
  
    if (displayIntl) {
      // eslint-disable-next-line default-case
      switch (phoneNumberLocale) {
        case 
        'IS':
          formattedBaseNumber = `+354 ${formattedBaseNumber}`;
          break;
        case 'US':
          formattedBaseNumber = `+1 ${formattedBaseNumber}`;
          break;
        case 'ET':
          formattedBaseNumber = `+251 ${formattedBaseNumber}`;
          break;
      }
    }
    setFormattedPhoneNumber(formattedBaseNumber);
  };
  
  const handleSave = (event) => {
    event.preventDefault();
    const normalizedSenderName = replaceSpecialChars(smsSenderName)
    const payload = {
      SmsActivity: switchStates.toString(),
      SmsLeadTime: parseInt(smsLeadTime, 10),
      company_ID: companyData.company_ID,
      phoneNumberLocale: phoneNumberLocale,
      displayIntl: isInternational.toString(),
      smsSenderName: normalizedSenderName,
      Body: defaultMessage,
      AutomaticNotifiationSMS: automaticMessage
    };

    axios.post('https://eunitstest.onrender.com/api/updatecompanydetails', payload)
      .then(response => {
        console.log('SMS settings updated:', response.data);
        setSuccessMessage("SMS settings successfully updated.");
        if(response.data) {
          updateCompanyData({ ...companyData, ...payload });
        } else {
          console.error('Updated company data not received');
        }
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      })
      .catch(error => {
        console.error('Failed to update SMS settings:', error);
        setSuccessMessage("Failed to update settings. Please try again.");
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      });
  };

  if (!isOpen) {
    return null;
  }

  const handleToggleSlider = () => {
    setSwitchStates(prevState => !prevState);
  };

  const handleIntlSwitch = () => {
    toggleInternationalFormat();
    const newDisplayIntl = !isInternational;
    setDisplayIntl(newDisplayIntl);

    const payload = {
      company_ID: companyData.company_ID,
      displayIntl: newDisplayIntl.toString(),
    };

    axios.post('https://eunitstest.onrender.com/api/updatecompanydetails', payload)
      .then(response => {
        console.log('International format updated:', response.data);
        updateCompanyData({ ...companyData, displayIntl: newDisplayIntl.toString() });
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      })
      .catch(error => {
        console.error('Failed to update international format:', error);
        setTimeout(() => {
          setSuccessMessage('');
        }, 2000);
      });
  };

  return (
    <div className={`fixed inset-0 flex items-start ${userData && userData.role === 'Company Admin' ? 'pt-0 pb-0' : 'pt-6'}  pb-6 justify-center bg-gray-500 bg-opacity-75 overflow-y-auto`}>
      <div className={`w-full ${userData && userData.role === 'Company Admin' ? 'md:ml-32 ml-4 mr-4' : 'max-w-md md:max-w-xl'} p-6`}
        style={{
          background: `linear-gradient(to bottom, #F3F4F6 ${gradientHeight}, #ffffff ${gradientHeight}, #ffffff 100%)`,
          borderBottom: '1px solid black'
        }}
      >
        <div className="flex flex-row gap-2 items-start justify-between pb-14">
          <div className='flex flex-col'>
            <div className='flex flex-row gap-6 md:gap-16'>
              <h2 className="font-semibold pt-1" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>{companyData.company}</h2>
              <h2 className="font-semibold text-gray-600 mt-1" style={{fontSize:'28px', lineHeight:'44px', letterSpacing:'2%'}}>Settings</h2>
            </div>
          </div>
          <img 
            onClick={closeModal} 
            src={close} alt="Close" 
            className="cursor-pointer mt-4 pl-8 md:mr-0" 
          />
        </div>

        {successMessage && (
          <div className="bg-green-200 text-green-800 p-2 mb-4">
            {successMessage}
          </div>
        )}

        <form onSubmit={handleSave}>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4 mb-4">
            <div className="col-span-1">
              <label htmlFor="phoneNumber" className="text-gray-900">
                Phone Number
              </label>
              <input
                id="phoneNumber"
                name="phoneNumber"
                placeholder="None"
                value={formattedPhoneNumber}
                onChange={(e) => setSmsLeadTime(e.target.value)}
                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                required
                disabled={true}
              ></input>
            </div>

            <div className="col-span-1">
              <label htmlFor="smsLeadTime" className="text-gray-900">
                SMS Lead Time (minutes)
              </label>
              <input
                id="smsLeadTime"
                name="smsLeadTime"
                placeholder="None"
                value={smsLeadTime}
                onChange={(e) => setSmsLeadTime(e.target.value)}
                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none cursor-pointer"
                required
              ></input>
            </div>

            <div className="col-span-1 cursor-pointer">
              <label htmlFor="displayIntl" className="text-gray-900">
                Display in International Format
              </label>
              <div className="custom-switch-bar1" onClick={handleIntlSwitch} style={{
                backgroundColor: displayIntl ? '#349999' : '#c4c4c4',
              }}>
                <span className='custom-switch-circle cursor-pointer' style={{
                  transform: displayIntl ? 'translateX(1.25rem)' : 'translateX(0)',
                  transition: 'transform 0.3s ease',
                }}></span>
              </div>
            </div>

            <div className="col-span-1 cursor-pointer">
              <label htmlFor="smsActivity" className="text-gray-900">
                Send SMS before booking
              </label>
              <div className="custom-switch-bar1 cursor-pointer" onClick={handleToggleSlider} style={{
                backgroundColor: switchStates ? '#349999' : '#c4c4c4',
                borderRadius: '0.75rem',
                transition: 'all 0.3s ease',
              }}>
                <span className='custom-switch-circle' style={{
                    transform: switchStates ? 'translateX(1.25rem)' : 'translateX(0)',
                    transition: 'transform 0.3s ease',
                }}></span>
              </div>
            </div>

            <div className="col-span-1 md:col-span-2">
              <label htmlFor="automaticMessage" className="text-gray-900">
                Automatic Notification SMS
              </label>
              <textarea
                id="automaticMessage"
                value={automaticMessage}
                onChange={(e) => setAutomaticMessage(e.target.value)}
                onClick={(e) => handleCursor(e, setAutomaticMessageCursor)}
                onSelect={(e) => handleCursor(e, setAutomaticMessageCursor)}
                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                required
              ></textarea>
              <div className="mt-2 flex flex-wrap gap-2">
                <button type="button" onClick={() => insertShortcode('{{booking id}}', 'automaticMessage')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking id'}
                </button>   
                <button type="button" onClick={() => insertShortcode('{{booking name}}', 'automaticMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start time}}', 'automaticMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{code}}', 'automaticMessage')} className="shortcode-btn bg-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'code'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking time}}', 'automaticMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking Start date}}', 'automaticMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking Start date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking date}}', 'automaticMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking End date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company name}}', 'automaticMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company id}}', 'automaticMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company id'}
                </button>
              </div>
            </div>

            <div className="col-span-1 md:col-span-2">
              <label htmlFor="defaultMessage" className="text-gray-900">
                  Default Text Message
              </label>
              <textarea
                id="defaultMessage"
                value={defaultMessage}
                onChange={(e) => setDefaultMessage(e.target.value)}
                onClick={(e) => handleCursor(e, setDefaultMessageCursor)}
                onSelect={(e) => handleCursor(e, setDefaultMessageCursor)}
                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                required
              ></textarea>
              <div className="mt-2 flex flex-wrap gap-2">
                <button type="button" onClick={() => insertShortcode('{{booking id}}', 'defaultMessage')} className="shortcode-btn hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking id'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking name}}', 'defaultMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-2 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking start time}}', 'defaultMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking start time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{code}}', 'defaultMessage')} className="shortcode-btn bg-blue-700 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'code'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking time}}', 'defaultMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking end time'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking Start date}}', 'defaultMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking Start date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{booking date}}', 'defaultMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'booking End date'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company name}}', 'defaultMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company name'}
                </button>
                <button type="button" onClick={() => insertShortcode('{{company id}}', 'defaultMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#349999"}}>
                  {'company id'}
                </button>

                {/* <button type="button" onClick={() => insertShortcode('{{company id}}', 'defaultMessage')} className="shortcode-btn  hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-full" style={{backgroundColor:"#373787"}}>
                  {'company id'}
                </button> */}
              </div>
            </div>

            <div className="col-span-1 md:col-span-2">
              <label htmlFor="defaultMessage" className="text-gray-900">
                Sms Sender Name
              </label>
              <input
                id="defaultMessage"
                value={smsSenderName}
                onChange={(e) => setSmsSenderName(e.target.value)}
                className="mt-1 px-2 py-1.5 w-full border resize-none text-gray-600 focus:outline-none"
                required
              ></input>
            </div>
          </div>

          {userData && userData.role === 'Company Admin' && (
            <div className="grid grid-cols-1 md:grid-cols-3 gap-3 mb-2">
            <div>
              <label htmlFor="firstName" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                First Name
              </label>
                <input
                  type="text"
                  id="firstName"
                  name="firstName"
                  placeholder='None'
                  value={userData.firstName}
                  className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />
            </div>
            <div>
              <label htmlFor="lastName" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                Last Name
              </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder='None'
                  value={userData.lastName}
                  // onChange={(e) => setLastName(e.target.value)}
                  className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />
            </div>

            <div>
              <label htmlFor="lastName" className=" text-md mb-2" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                Role
              </label>
                <input
                  type="text"
                  id="lastName"
                  name="lastName"
                  placeholder='None'
                  value={userData.role}
                  className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                />
            </div>
          </div>
            )  
          }

          <div className="flex md:flex-row md:justify-end flex-col pt-6 gap-3">
            <button onClick={closeModal} className="px-3 py-2 text-gray-600 border focus:outline-none focus:ring focus:border-gray-300">
              Discard
            </button>
            <button type="submit" className="py-2 px-3 text-white bg-black hover:bg-teal-600 focus:outline-none focus:ring focus:border-teal-300">
              Save Changes
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default SettingsModal;
