import React, { useEffect, useState } from 'react';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import DataTable from 'react-data-table-component';
import trash from '../Assets/trash.svg';
import { useSwipeable } from 'react-swipeable';
import { useLocation } from 'react-router-dom';
import './styles.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import icon from '../Assets/iconS.svg';
import download from '../Assets/download.svg';
import search from '../Assets/search.svg';
import { MdArrowUpward, MdArrowDownward } from 'react-icons/md';
import { usePhoneNumberFormat } from './Provided';

const SortIcon = ({ isSortedDesc }) => {
  return isSortedDesc ? <MdArrowDownward /> : <MdArrowUpward />;
};

const customStyles = {
  rows: {
    style: {
      minHeight: '52px',
    },
  },
  headCells: {
    style: {
      backgroundColor: '#F9FAFB',
      paddingLeft: '8px',
      paddingRight: '8px',
      whiteSpace: 'nowrap', 
    },
  },
  cells: {
    style: {
      paddingLeft: '8px',
      paddingRight: '8px',
      whiteSpace: 'normal',
    },
  },
};

const ViewSMSLog = ({setIsMenuOpen}) => {
  const [bookings, setBookings] = useState([]);
  const [filter, setFilter] = useState('');
  const location = useLocation();
  const [userData, setUserData] = useState(null);
  const { isInternational } = usePhoneNumberFormat();
  const [hoveredRow, setHoveredRow] = useState(null);
  const [isHovered, setIsHovered] = useState(false);
  const [tooltipPosition, setTooltipPosition] = useState({ x: 0, y: 0 });

  const handleMouseEnter = (event) => {
    const { clientX, clientY } = event;
    setTooltipPosition({ x: clientX, y: clientY });
  };


  const headers = [
    { label: "Date", key: "datetime" },
    { label: "Customer", key: "name" },
    { label: "Phone Number", key: "phone_number" },
    { label: "Message", key: "message" },
    { label: "Status", key: "status" },
    { label: "Error Status", key: "errorStatus" },
    { label: "Company", key: "company" },
    { label: "Segments", key: "totalSMSsent" },
  ];

  const calculateSegments = (message) => {
    const segmentSize = 130;
    const messageLength = message.length;
    return Math.ceil(messageLength / segmentSize);
  }; 
  
  const groupByCompanyAndTime = (bookings, period) => {
    const groupedData = {};
    bookings.forEach((booking) => {
      const company = booking.company;
      const messageSegments = calculateSegments(booking.message);
      const date = new Date(booking.datetime);
      let timeKey;
      if (period === 'week') {
        const week = Math.ceil(date.getDate() / 7);
        timeKey = `${date.getFullYear()}-W${week}`;
      } else if (period === 'month') {
        const month = date.getMonth() + 1;
        timeKey = `${date.getFullYear()}-M${month}`;
      }
      if (!groupedData[company]) {
        groupedData[company] = {};
      }
      if (!groupedData[company][timeKey]) {
        groupedData[company][timeKey] = 0;
      }
      groupedData[company][timeKey] += messageSegments;
    });
  
    return groupedData;
  };  

  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []);

  useEffect(() => {
    const groupedData = groupByCompanyAndTime(bookings, 'week');
    console.log(groupedData);
  }, [bookings]);

  const storedRole = localStorage.getItem('role');
  const storedCompany = localStorage.getItem('company');

  const useWindowSize = () => {
    const [size, setSize] = useState([window.innerHeight, window.innerWidth]);  
    useEffect(() => {
      const handleResize = () => {
        setSize([window.innerHeight, window.innerWidth]);
      };
      window.addEventListener('resize', handleResize);
      return () => window.removeEventListener('resize', handleResize);
    }, []);
    return size;
  };
  
  const [height, width] = useWindowSize();
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const selectedTab = searchParams.get('selectedTab');
    if (selectedTab === 'next') {
    }
  }, [location.search]);

  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const filterFromQuery = searchParams.get('company');
    if (filterFromQuery) {
      setFilter(filterFromQuery);
      console.log('Filter set from query:', filterFromQuery);
    }
  }, [location.search]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        let response;
        let data;
  
        if (storedRole === 'System Admin') {
          response = await axios.get('https://eunitstest.onrender.com/api/getsmslogs');
          data = response.data.map(booking => ({
            ...booking,
            phone_number: formatPhoneNumber(booking.phone_number, isInternational)
          }));
        } else if (storedRole === 'Company User' || storedRole === 'Company Admin') {
          response = await axios.get('https://eunitstest.onrender.com/api/getsmslogs');
          data = response.data
            .filter(booking => booking.company === storedCompany)
            .map(booking => ({
              ...booking,
              phone_number: formatPhoneNumber(booking.phone_number, isInternational)
            }));
        }
  
        data.sort((a, b) => new Date(b.datetime) - new Date(a.datetime));
        setBookings(data);
      } catch (error) {
        console.error('Failed to fetch bookings:', error);
      }
    };
  
    fetchData();
  }, []);

  const formatPhoneNumber = (phone) => {
    const cleaned = ('' + phone).replace(/[^\d]/g, '');
    const partOne = cleaned.slice(0, 3);
    const partTwo = cleaned.slice(3, 6);
    const partThree = cleaned.slice(6);
  
    return `(IS) +${partOne} ${partTwo} ${partThree}`;
  };
  
  const diacriticChars = {
    'à': 'a', 'á': 'a', 'â': 'a', 'ã': 'a', 'ä': 'a', 'å': 'a', 'æ': 'ae',
    'ç': 'c', 'è': 'e', 'é': 'e', 'ê': 'e', 'ë': 'e',
    'ì': 'i', 'í': 'i', 'î': 'i', 'ï': 'i',
    'ð': 'd', 'ñ': 'n',
    'ò': 'o', 'ó': 'o', 'ô': 'o', 'õ': 'o', 'ö': 'o',
    'ø': 'o', 'œ': 'oe',
    'ß': 'ss',
    'ù': 'u', 'ú': 'u', 'û': 'u', 'ü': 'u',
    'ý': 'y', 'ÿ': 'y',
    'ā': 'a', 'ă': 'a', 'ą': 'a', 'ć': 'c', 'č': 'c', 'ç': 'c',
    'ď': 'd', 'đ': 'd', 'ē': 'e', 'ĕ': 'e', 'ė': 'e', 'ę': 'e', 'ě': 'e',
    'ğ': 'g', 'ġ': 'g', 'ģ': 'g', 'ħ': 'h', 'ĩ': 'i', 'ī': 'i', 'ĭ': 'i', 'į': 'i', 'ı': 'i',
    'ĳ': 'ij', 'ķ': 'k', 'ĸ': 'k', 'ĺ': 'l', 'ļ': 'l', 'ľ': 'l', 'ŀ': 'l', 'ł': 'l',
    'ń': 'n', 'ņ': 'n', 'ň': 'n', 'ŋ': 'n', 'ō': 'o', 'ŏ': 'o', 'ő': 'o', 'œ': 'oe',
    'ŕ': 'r', 'ŗ': 'r', 'ř': 'r', 'ś': 's', 'š': 's', 'ŝ': 's', 'ş': 's', 'ș': 's', 'ß': 'ss',
    'ť': 't', 'ţ': 't', 'ț': 't', 'ŧ': 't', 'ũ': 'u', 'ū': 'u', 'ŭ': 'u', 'ů': 'u', 'ű': 'u', 'ų': 'u',
    'ŵ': 'w', 'ŷ': 'y', 'ÿ': 'y', 'ź': 'z', 'ż': 'z', 'ž': 'z',
    'í': 'i', 'Í': 'i',
    'a': 'a', 'b': 'b', 'c': 'c', 'd': 'd', 'e': 'e', 'f': 'f', 'g': 'g', 'h': 'h',
    'i': 'i', 'j': 'j', 'k': 'k', 'l': 'l', 'm': 'm', 'n': 'n', 'o': 'o', 'p': 'p',
    'q': 'q', 'r': 'r', 's': 's', 't': 't', 'u': 'u', 'v': 'v', 'w': 'w', 'x': 'x',
    'y': 'y', 'z': 'z'
  };

  const normalizeString = (str) => {
    return str.replace(/[à]/g, (match) => diacriticChars[match] || match);
  };

  const filteredBookings = () => {
    if (!filter || filter.length < 3) {
      return bookings;
    }
  
    const normalizedFilter = normalizeString(filter).toLowerCase();
    return bookings.filter(booking => {
      const searchText = Object.values(booking).join(' ').toLowerCase();
      return searchText.includes(normalizedFilter);
    });
  };  
  
  const RowActions = ({ onDelete, row }) => {
    console.log('SMS ID:', row.smsid);
    return (
      <div className="flex flex-row text-gray-600">
        <img src={trash} alt="Delete" className="w-5 h-5 object-cover cursor-pointer mr-2" onClick={(event) => onDelete(row.smsid, event)} />
        <button className="text-red-500 mr-4 cursor-pointer" onClick={(event) => onDelete(row.smsid, event)}>Delete</button>
      </div>
    );
  };  

  const formatDate = (timestamp) => {
    const date = new Date(timestamp);
    const formattedDate = date.toISOString().split('T')[0];
    const formattedTime = date.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    return `${formattedDate} ${formattedTime}`;
  };

  const splitMessage = (message) => {
    const splitIntoParts = (str, partLength) => {
      const parts = [];
      for (let i = 0; i < str.length; i += partLength) {
        parts.push(str.substring(i, i + partLength));
      }
      return parts;
    };
  
    const messageParts = splitIntoParts(message, 100);
    return (
      <div>
        {messageParts.map((part, index) => (
          <p key={index}>{part}</p>
        ))}
      </div>
    );
  };  
  
  const getColumns = () => {
    const baseColumns = [
      {
        name: 'Date',
        selector: row => new Date(row.datetime),
        sortable: true,
        sortIcon: <SortIcon />,
        cell: row => formatDate(row.datetime),
        grow: 2,
      },
      {
        name: 'Customer',
        selector: row => row.name,
        sortable: true,
        sortIcon: <SortIcon />,
        format: row => (
          <div className="whitespace-normal sm:whitespace-nowrap overflow-hidden text-ellipsis">
            <div className="font-bold text-black pb-1 hover:underline cursor-pointer">
              {row.name}
            </div>
            <div className="text-sm">{row.email}</div>
          </div>
        ),
        grow: 2,
        wrap: true
      },
      {
        name: 'Origin',
        selector: row => row.Integration,
        sortable: true,
        sortIcon: <SortIcon />,
        omit: window.innerWidth < 768
      },
      {
        name: 'Phone Number',
        selector: row => row.phone_number,
        sortable: true,
        grow: 2,
        wrap: true,
        cell: row => row.phone_number,
      },
      {
        name: 'Message',
        selector: row => row.message,
        sortable: true,
        cell: (row, index) => (
          <div
          onMouseEnter={(e) => {
            handleMouseEnter(e);
            setHoveredRow(row.smsid);
          }}
          onMouseLeave={() => setHoveredRow(null)}
          >
            <span className="flex items-center">
              <FontAwesomeIcon
                icon={faEye}
                style={{ marginLeft: '8px', cursor: 'pointer' }}
              />
            </span>
            {hoveredRow === row.smsid && (
              <div
                className="fixed bg-gray-800 text-white p-3 rounded-lg shadow-lg z-50 max-w-xs whitespace-pre-wrap break-words leading-relaxed"
                style={{
                  left: `${tooltipPosition.x + 10}px`,
                  top: `${tooltipPosition.y + 10}px`,
                  zIndex: 9000,
                  marginBottom: '8px',
                  borderRadius: '5px',
                }}
              >
                {row.message}
              </div>
            )}

          </div>
        ),
      },
      {
        name: 'Status',
        selector: row => row.status,
        sortable: true,
        sortIcon: <SortIcon />,
        grow: 1.5,
        wrap: true
      },
      {
        name: 'Error Status',
        selector: row => row.error_msg,
        sortable: true,
        sortIcon: <SortIcon />,
        grow: 1.5,
        wrap: true,
        cell: row => {
          let backgroundColor;
          let displayMessage = row.error_msg;

          if (row.error_msg === "No error") {
            backgroundColor = 'green';
          } else if (row.status === "failed" || row.error_msg === 'null') {
            backgroundColor = 'red';
            displayMessage = 'Error';
          } else {
            backgroundColor = '';
          }

          return (
            <div
              style={{
                backgroundColor: backgroundColor,
                color: backgroundColor ? 'white' : 'black',
                padding: '10px',
                borderRadius: '5px',
                cursor: 'pointer',
                transition: 'background-color 0.3s ease',
              }}
              onMouseEnter={() => setIsHovered(row.smsid)}
              onMouseLeave={() => setIsHovered(false)}                                                                                                                                                                                                                                                                                                                                                             
              title={row.error_msg}
            >
              {isHovered === row.smsid && row.status === "failed" ? row.error_msg : displayMessage}
            </div>
          );
        }
      },           
      {
        name: 'Segments',
        selector: row => row.totalSMSsent,
        sortable: true,
        sortIcon: <SortIcon />,
        grow: 1.5,
        wrap: true,
        cell: row => calculateSegments(row.message),
      },
      {
        name: 'Actions',
        cell: row => <RowActions onDelete={handleDelete} row={row} />,
      },
    ];
  
    if (userData && userData.role === 'System Admin') {
      baseColumns.splice(6, 0, { name: 'Company', selector: row => row.company, sortable: true });
    }
    return baseColumns;
  };
  
  const columns = getColumns();
  const csvData = filteredBookings().map(booking => ({
    datetime: new Date(booking.datetime).toLocaleString(),
    name: booking.name,
    phone_number: booking.phone_number,
    message: booking.message,
    status: booking.status,
    company: booking.company,
    totalSMSsent: calculateSegments(booking.message),
  }));

  const handleDelete = async (smsid, event) => {
    event.preventDefault();
    if (!smsid) {
      alert('Invalid booking ID');
      return;
    }
  
    try {
        const response = await axios.post('https://eunitstest.onrender.com/api/deletesmslogs', { smsid});
        if (response.status === 200) {
            const newSMS = bookings.filter(booking => booking.smsid !== smsid);
            setBookings(newSMS);
        } else {
            const errorMessage = response.data.error || 'Unknown error occurred while deleting booking';
            console.error('Error deleting booking:', errorMessage);
            alert(errorMessage);
        }
    } catch (error) {
      console.error('Error deleting booking:', error);
      alert('Failed to delete booking: ' + error.message);
    }
  };

  const handleFilterChange = (value) => {
    setFilter(value);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });

  return (
    <div className='mx-16 p-8'>
      <div {...handlers} className="pb-2">
        <div className='md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden' style={{color:"#010808"}}>
          <img
            src={icon}
            alt="User"
            className=" bg-gray-100"
          />
          <FontAwesomeIcon icon={faChevronRight} className="" />
          <span className='font-bold' style={{lineHeight:'20px', fontSize:'14px'}}>View SMS Log</span>
        </div>

        <div className="bg-gray-100 pt-0 md:pt-6 flex flex-col items-start lg:flex-row lg:items-center lg:justify-between md:flex-row md:items-center md:justify-between" style={{color:"#010808"}}>
          <div className="flex flex-col pb-4 md:pb-0">
            <span className="font-bold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>View SMS Log</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>View all your SMS log</p>
          </div>

          <div className="flex flex-col">
            <div className="flex flex-col items-start pt-2 lg:flex-row lg:items-center md:flex-row md:items-center gap-2">
              <CSVLink
                data={csvData}
                headers={headers}
                filename="all_smslog.csv"
                className="custom-widthB flex items-center justify-center gap-2 border bg-white border-gray-300 md:px-2 py-2 md:mr-0"
                style={{ color:'#010808' }}
              >
                <img src={download} alt="Download" className="" />
                <span className='font-bold' style={{fontSize:'18px', lineHeight:'28px', color:'010808', letterSpacing:'0%'}}>Export</span>
              </CSVLink>
              </div>
          </div>
        </div>

        <div className="hidden md:flex flex-row justify-end">
          <div className="relative">
            <img
              src={search}
              alt="User"
              className="custom-widthB absolute mt-5 pt-1 transform -translate-y-1/2 left-3 text-gray-500"
            />
            <input
              className="px-10 text-black py-2 border focus:outline-none"
              style={{ 
                '&:focus': {
                  borderColor: '#349999'
                },
              }}
              type="text"
              placeholder="Search by keyword"
              value={filter}
              onChange={(e) => handleFilterChange(e.target.value)}
            />
          </div>
        </div>
        
        <div className="md:hidden relative">
          <div className="relative mr-2 mb-2">
            <img
              src={search}
              alt="User"
              className="absolute mt-5 pt-1 transform -translate-y-1/2 left-3 text-gray-500"
            />
            <input
              className="custom-widthB w-full text-gray-500 py-2 px-12 border focus:outline-none"
              type="text"
              placeholder="Search by Keyword"
              value={filter}
              onChange={(e) => handleFilterChange(e.target.value)}
            />          
          </div>
        </div>

      </div>
        <div className='overflow-x-auto'>
          <DataTable
            columns={columns}
            data={filteredBookings()}
            customStyles={customStyles}
            pagination
            persistTableHead
            highlightOnHover
            sortIcon={<SortIcon />}
          />
        </div>
    </div>
  );
};

export default ViewSMSLog;
