import React, { useState, useEffect } from 'react';
import 'react-datepicker/dist/react-datepicker.css';
import axios from 'axios';
import './styles.css';
import close from '../Assets/close.svg';
import EmailPopup from './EmailModal';

const NewBookingForm = ({ closeModal, editMode, bookingData, updateTableDataAdd, onBookingUpdated }) => {
  
  const [valid, setValid] = useState(false);
  const [formMode, setFormMode] = useState('single');
  const [registrationStatus, setRegistrationStatus] = useState(null);
  const [responseMessage, setResponseMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [updateStatus, setUpdateStatus] = useState(null);
  const [userData, setUserData] = useState(null);
  const [formErrors, setFormErrors] = useState({});
  const [companies, setCompanies] = useState([{ company: 'None', CompanyID: null }]);
  const [formData, setFormData] = useState({
    bookingCustomerName: '',
    bookingCustomerPhone: '',
    bookingCode: '',
    bookingStartDate: '',
    bookingStartsAtTime: '',
    bookingEndDate: '',
    bookingEndsAtTime: '',
    bookingCompanyEmail:'',
    eConformationEmail: false,
    eConformationText: 'false',
    repeats: 'Daily'
  });

  const [gradientHeight, setGradientHeight] = useState('');
  const [showEmailPopup, setShowEmailPopup] = useState(false);
  const [emailSubject, setEmailSubject] = useState('');
  const [emailBody, setEmailBody] = useState('');
  const [emailError, setEmailError] = useState('');

  const stripHtml = (html) => {
    return html.replace(/<[^>]*>?/gm, '');
  }

  useEffect(() => {
    const updateGradientHeight = () => {
      const height = formMode === 'recurring' 
        ? (window.innerWidth < 640 ? '10.7%' : '10.3%')
        : (window.innerWidth < 640 ? '12.7%' : '15%');
      
      setGradientHeight(height);
    };
  
    window.addEventListener('resize', updateGradientHeight);
    updateGradientHeight();
    
    return () => {
      window.removeEventListener('resize', updateGradientHeight);
    };
  }, [formMode]);

  let companyID;
  if (userData && (userData.role === 'Company User'|| userData.role ==='Company Admin')) {
    const companyDetail = companies.find(c => c.company === userData.company);
    companyID = companyDetail ? companyDetail.company_ID : null;
  } else {
    const selectedCompany = companies.find(company => company.company === formData.company);
    companyID = selectedCompany ? selectedCompany.company_ID : null;
  }

  const handleChangeCheckbox = (e) => {
    const { id, checked } = e.target;
    setFormData(prevData => ({
      ...prevData,
      [id]: checked ? "true" : "false"
    }));
  
    if (id === 'eConformationEmail') {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (checked && emailPattern.test(formData.bookingCompanyEmail)) {
        setShowEmailPopup(true);
        setEmailError('');
      } else {
        setShowEmailPopup(false);
        if (checked) {
          setEmailError('Please enter a valid email address before enabling send email.');
        } else {
          setEmailError('');
        }
      }
    }
  };  

  const validateForm = () => {
    const errors = {};
    if (!formData.bookingCustomerName.trim()) errors.bookingCustomerName = 'Name is required.';
    // if (!formData.bookingCustomerPhone.trim()) errors.bookingCustomerPhone = 'Phone is required.';
    if (formData.bookingCode.length !== 6) errors.bookingCode = 'Code must be 6 characters long.';
    if (!formData.bookingStartDate.trim()) errors.bookingStartDate = 'Start date is required.';
    if (!formData.bookingEndDate.trim()) errors.bookingEndDate = 'End date is required.';
    // if (!formData.bookingCompanyEmail.trim()) errors.bookingCompanyEmail = 'Email is required.';
    if (!formData.bookingStartsAtTime.trim())errors.bookingStartsAtTime = 'Start time is required';
    if (!formData.bookingEndsAtTime.trim())errors.bookingEndsAtTime = 'End time is required';

    if (userData && userData.role !== 'Company User' && !formData.company) {
      errors.company = 'Company is required';
    }
  
    setFormErrors(errors);
    return Object.keys(errors).length === 0;
  };
  
  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []); 

  useEffect(() => {
    async function fetchCompanies() {
        try {
            const response = await axios.get('https://eunitstest.onrender.com/api/getcompanydetails');
            if (response.status === 200) {
                setCompanies([{ company: 'None', CompanyID: null }, ...response.data]);
            }
        } catch (error) {
            console.error('Failed to fetch companies:', error);
        }
    }
    fetchCompanies();

    // async function fetchConnections() {
    //     try {
    //         const response = await axios.get('https://eunitstest.onrender.com/api/getconnectiondetails');
    //         if (response.status === 200) {
    //             setConnections([{ Connection: 'None', connectionID: null }, ...response.data.map(c => ({ Connection: c.Connection || 'None', connectionID: c.id }))]);
    //         }
    //     } catch (error) {
    //         console.error('Failed to fetch connections:', error);
    //     }
    // }
    // fetchConnections();
  }, []);

  useEffect(() => {
    async function fetchCompanies() {
      try {
        const response = await axios.get('https://eunitstest.onrender.com/api/getcompanydetails');
        if (response.status === 200) {
          setCompanies(response.data);
        }
      } catch (error) {
        console.error('Failed to fetch companies:', error);
      }
    }
    fetchCompanies();
  }, []);
  
  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'company') {
      const selectedCompany = companies.find(company => company.company === value);
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
        company_ID: selectedCompany ? selectedCompany.company_ID : '',
      }));
    } 
    // else if (name === 'Connection') {
    //   // setFormData(prevData => ({
    //   //   ...prevData,
    //   //   [name]: value,
    //   // }));
    // } 
    else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value,
      }));
    }
  };

  function formatDate(dateString) {
    if (!dateString) return '';
    
    const parts = dateString.split('/');
    if (parts.length === 3) {
      return `${parts[0]}-${parts[1]}-${parts[2]}`;
    }
    return dateString;
  }

  useEffect(() => {
    if (editMode && bookingData) {
      setFormData({
        bookingCustomerName: bookingData.bookingCustomerName || '',
        bookingCustomerPhone: bookingData.bookingCustomerPhone || '',
        bookingCode: bookingData.bookingCode || '',
        bookingStartDate: bookingData.bookingStartDate ? formatDate(bookingData.bookingStartDate) : '',
        bookingStartsAtTime: bookingData.bookingStartsAtTime || '',
        bookingEndDate: bookingData.bookingEndDate ? formatDate(bookingData.bookingEndDate) : '',
        bookingEndsAtTime: bookingData.bookingEndsAtTime || '',
        bookingCompanyEmail: bookingData.bookingCompanyEmail || '',
        Connection: bookingData.Connection || 'None',
        company: bookingData.company || 'None',
        company_ID: bookingData.company_ID || null,
        isRecurring:bookingData.isRecurring || null
      });
    }
  }, [editMode, bookingData]);

  useEffect(() => {
    setValid(
      formData.bookingCustomerName &&
      formData.bookingCustomerPhone &&
      formData.bookingStartsAtTime &&
      formData.bookingEndsAtTime &&
      formData.bookingCode?.length === 6 &&
      formData.bookingStartDate &&
      formData.bookingCompanyEmail &&
      formData.bookingEndDate
    );
  }, [formData]);
  
  useEffect(() => {
    if (formMode === 'single') {
      setValid(
        formData.bookingCustomerName &&
        formData.bookingCustomerPhone &&
        formData.bookingCode?.length === 6 &&
        formData.bookingStartDate &&
        formData.bookingEndDate &&
        formData.bookingCompanyEmail
      );
    } else if (formMode === 'recurring') {
      setValid(
        formData.bookingCustomerName &&
        formData.bookingCustomerPhone &&
        formData.bookingCode?.length === 6 &&
        formData.bookingStartDate &&
        formData.bookingEndDate &&
        formData.bookingCompanyEmail &&
        formData.company
      );
    }
  }, [formMode, formData]);

  const toggleFormMode = (mode) => {
    setFormMode(mode);
  };
  
  const handleSave = async () => {
    if (!validateForm()) {
      console.error('Validation failed.');
      return;
    }
  
    const isRecurring = formMode === 'recurring' ? 'true' : 'false';
  
    if (editMode) {
      handleUpdateBooking();
    } else {
      const bookingDetails = {
        ...formData,
        userId: userData?.userId,
        company: userData?.role === 'Company User' ? userData.company : formData.company,
        company_ID: companyID,
        bookingStartsAtTime: Math.floor(new Date(`${formData.bookingStartDate}T${formData.bookingStartsAtTime}:00Z`).getTime() / 1000),
        bookingEndsAtTime: Math.floor(new Date(`${formData.bookingEndDate}T${formData.bookingEndsAtTime}:00Z`).getTime() / 1000),
        Integration: "web",
        status: "notdone",
        eConformationText: formData.eConformationText,
        isRecurring: isRecurring,
      };
      try {
        const response = await axios.post('https://eunitstest.onrender.com/api/addbooking', bookingDetails);
        if (response.status === 200) {
          updateTableDataAdd(response.data.result);
          setRegistrationStatus('success');
        } else {
          throw new Error('Failed to process booking');
        }
      } catch (error) {
        console.error('Error submitting the booking:', error);
        setRegistrationStatus('failure');
      }
    }
  };  

  const handleUpdateBooking = () => {
    const baseURL = 'https://eunitstest.onrender.com/api';
    const payload = {
      bookingId: bookingData.bookingId,
      company_ID: companyID,
      ...formData,
      Integration: "web",
      status: "notdone",
      userId: userData.userId,
      eConformationText: formData.eConformationText,
      isRecurring: formData.isRecurring,
    };

    fetch(`${baseURL}/updatebooking`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json"
      },
      body: JSON.stringify(payload)
    })
    .then(response => {
      if (response.ok) {
        return response.json();
      } else {
        throw new Error('Failed to update booking. Status: ' + response.status);
      }
    })
    .then(data => {
      if (data.error) {
        throw new Error('Error in response data: ' + data.error);
      }
      setUpdateStatus("success");
      onBookingUpdated(data);
    })
    .catch(error => {
      console.error("Error updating booking:", error);
      setUpdateStatus("failure");
    });
  };

  const handleSendEmail = async () => {
    const plainTextBody = stripHtml(emailBody);
    if (!emailSubject.trim() || !emailBody.trim()) {
      alert('Please provide both subject and message body.');
      return;
    }
  
    try {
      const response = await axios.post('https://eunitstest.onrender.com/api/sendmail', {
        bookingCode: formData.bookingCode,
        Subject: emailSubject,
        Body: plainTextBody,
        company_ID: companyID,
        receiverEmail: formData.bookingCompanyEmail,
      });
      
      if (response.status === 200) {
        setResponseMessage('Email sent successfully!');
          setTimeout(() => {
            setResponseMessage('');
          }, 5000);
      } else {
        setErrorMessage(`Failed to send Email`);
          setTimeout(() => {
            setErrorMessage('');
          }, 5000);
        throw new Error('Failed to send email.');
      }
    } catch (error) {
      console.error('Error sending email:', error);
      alert('Failed to send email.');
    }
  }; 
  
  const renderRepeatOptions = () => {
    switch (formData.repeats) {
      case 'Daily':
      return (
        <>
          <div className="bg-white p-6 rounded-lg shadow space-y-4">
            <div className="flex items-center gap-2">
              <label className="text-sm font-medium text-gray-900">Repeats every:</label>
              <input
                type="number"
                name="repeatInterval"
                min="1"
                value={formData.repeatInterval}
                onChange={handleChange}
                className="ml-2 bg-white border py-1.5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
              />
              <span className="block text-sm font-medium text-gray-700">day</span>
            </div>
          <div>
          </div>

          <div>
            <fieldset>
              <legend className="text-sm font-semibold text-gray-700">Ends:</legend>
              <div className="mt-2">
                <div className="flex items-center mb-4">
                    <input
                      type="radio"
                      id="never"
                      name="repeatEnds"
                      value="Never"
                      checked={formData.repeatEnds === 'Never'}
                      onChange={handleChange}
                      className="text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                  <label htmlFor="never" className="ml-2 block text-sm text-gray-900">Never</label>
                </div>
                <div className="flex items-center mb-4">
                    <input
                      type="radio"
                      id="after"
                      name="repeatEnds"
                      value="After"
                      checked={formData.repeatEnds === 'After'}
                      onChange={handleChange}
                      className="text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                    <label htmlFor="after" className="ml-2 text-sm font-medium text-gray-900">
                      After
                    </label>
                    <input
                      type="number"
                      name="occurrences"
                      value={formData.occurrences}
                      onChange={handleChange}
                      disabled={formData.repeatEnds !== 'After'}
                      className="ml-2 py-1.5 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                      min="1"
                    />
                    <span className="ml-1 text-sm text-gray-900">times</span>
                  </div>
                  <div className="flex items-center">
                    <input
                      type="radio"
                      id="on"
                      name="repeatEnds"
                      value="On"
                      checked={formData.repeatEnds === 'On'}
                      onChange={handleChange}
                      className="text-gray-700 border-gray-300"
                    />
                    <label htmlFor="on" className="ml-2 text-sm font-medium text-gray-900">
                      On
                    </label>
                    <input
                      type="date"
                      name="repeatEndDate"
                      value={formData.repeatEndDate}
                      onChange={handleChange}
                      disabled={formData.repeatEnds !== 'On'}
                      className="ml-2 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg p-2.5 focus:outline-none"
                    />
                  </div>
              </div>
            </fieldset>
          </div>
          </div>
        </>
      );
      case 'Weekly':
        return (               
          <>
           <div className="bg-white p-6 rounded-lg shadow space-y-4 ">
            <div className="flex items-center gap-2">
              <label className="text-sm font-medium text-gray-900">Repeats every:</label>
              <input
                type="number"
                name="repeatInterval"
                min="1"
                value={formData.repeatInterval}
                onChange={handleChange}
                className="ml-2 bg-white border py-1.5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
              />
              <span className="block text-sm font-medium text-gray-700">week(s)</span>
            </div>

          <div>
          <fieldset>
            <legend className="text-sm font-semibold text-gray-700">Repeat on:</legend>
            <div className="mt-2 grid grid-cols-4 gap-4">
              {['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'].map(day => (
                <div key={day} className="flex items-center">
                  <input type="checkbox" id={day} name={day} className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300 rounded"/>
                  <label htmlFor={day} className="ml-2 block text-sm text-gray-900">{day}</label>
                </div>
              ))}
            </div>
          </fieldset>
        </div>

        <div>
          <fieldset>
            <legend className="text-sm font-semibold text-gray-700">Ends:</legend>
            <div className="mt-2">
              <div className="flex items-center mb-4">
                  <input
                    type="radio"
                    id="never"
                    name="repeatEnds"
                    value="Never"
                    checked={formData.repeatEnds === 'Never'}
                    onChange={handleChange}
                    className="text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                <label htmlFor="never" className="ml-2 block text-sm text-gray-900">Never</label>
              </div>
              <div className="flex items-center mb-4">
                  <input
                    type="radio"
                    id="after"
                    name="repeatEnds"
                    value="After"
                    checked={formData.repeatEnds === 'After'}
                    onChange={handleChange}
                    className="text-blue-600 border-gray-300 focus:ring-blue-500"
                  />
                  <label htmlFor="after" className="ml-2 text-sm font-medium text-gray-900">
                    After
                  </label>
                  <input
                    type="number"
                    name="occurrences"
                    value={formData.occurrences}
                    onChange={handleChange}
                    disabled={formData.repeatEnds !== 'After'}
                    className="ml-2 py-1.5 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                    min="1"
                  />
                  <span className="ml-1 text-sm text-gray-900">times</span>
                </div>
                <div className="flex items-center">
                  <input
                    type="radio"
                    id="on"
                    name="repeatEnds"
                    value="On"
                    checked={formData.repeatEnds === 'On'}
                    onChange={handleChange}
                    className="text-gray-700 border-gray-300"
                  />
                  <label htmlFor="on" className="ml-2 text-sm font-medium text-gray-900">
                    On
                  </label>
                  <input
                    type="date"
                    name="repeatEndDate"
                    value={formData.repeatEndDate}
                    onChange={handleChange}
                    disabled={formData.repeatEnds !== 'On'}
                    className="ml-2 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                  />
                </div>
            </div>
          </fieldset>
        </div>
      </div>

      </>
        );
      case 'Monthly':
        return (
          <>
            <div className="bg-white p-6 rounded-lg shadow space-y-4">
              <div className="flex items-center gap-2">
                <label className="text-sm font-medium text-gray-900">Repeats every:</label>
                <input
                  type="number"
                  name="repeatInterval"
                  min="1"
                  value={formData.repeatInterval}
                  onChange={handleChange}
                  className="ml-2 bg-white border py-1.5 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
                />
                <span className="block text-sm font-medium text-gray-700">Month</span>
              </div>

              <div>
                <fieldset>
                  <legend className="text-sm font-semibold text-gray-700">Repeat on:</legend>
                  <div className="mt-2 space-y-4">
                    <div className="flex items-center">
                      <input type="radio" id="onDay" name="repeatType" className="h-4 w-4 border-gray-300"/>
                      <label htmlFor="onDay" className="ml-2 text-sm font-medium text-gray-900">On Day</label>
                      <input type="number" min="1" max="31" defaultValue="1" className="ml-2 w-20 rounded-md border border-gray-300 shadow-sm focus:ring-opacity-50"/>
                    </div>
                    <div className="flex items-center">
                      <input type="radio" id="onThe" name="repeatType" className="focus:ring-indigo-500 h-4 w-4 text-indigo-600 border-gray-300"/>
                      <label htmlFor="onThe" className="ml-2 text-sm font-medium text-gray-900">The</label>
                      <select className="ml-2 w-24 rounded-md  border border-gray-300 shadow-sm  focus:ring-opacity-50">
                        <option>first</option>
                        <option>second</option>
                        <option>third</option>
                        <option>fourth</option>
                        <option>last</option>
                      </select>
                      <select className="ml-2 w-24 rounded-md border border-gray-300 shadow-sm focus:ring-opacity-50">
                        <option>Sunday</option>
                        <option>Monday</option>
                        <option>Tuesday</option>
                        <option>Wednesday</option>
                        <option>Thursday</option>
                        <option>Friday</option>
                        <option>Saturday</option>
                      </select>
                    </div>
                  </div>
                </fieldset>
              </div>

              <div className="mt-2">
                  <fieldset>
              <legend className="text-sm font-semibold text-gray-700">Ends:</legend>
              <div className="mt-2">
                <div className="flex items-center mb-4">
                <input
                      type="radio"
                      id="never"
                      name="repeatEnds"
                      value="Never"
                      checked={formData.repeatEnds === 'Never'}
                      onChange={handleChange}
                      className="text-blue-600 border-gray-300 focus:ring-blue-500"
                    />
                  <label htmlFor="never" className="ml-2 block text-sm text-gray-900">Never</label>
                </div>
        <div className="flex items-center mb-4">
            <input
              type="radio"
              id="after"
              name="repeatEnds"
              value="After"
              checked={formData.repeatEnds === 'After'}
              onChange={handleChange}
              className="text-blue-600 border-gray-300 focus:ring-blue-500"
            />
            <label htmlFor="after" className="ml-2 text-sm font-medium text-gray-900">
              After
            </label>
            <input
              type="number"
              name="occurrences"
              value={formData.occurrences}
              onChange={handleChange}
              disabled={formData.repeatEnds !== 'After'}
              className="ml-2 py-1.5 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
              min="1"
            />
            <span className="ml-1 text-sm text-gray-900">times</span>
          </div>
          <div className="flex items-center">
            <input
              type="radio"
              id="on"
              name="repeatEnds"
              value="On"
              checked={formData.repeatEnds === 'On'}
              onChange={handleChange}
              className="text-gray-700 border-gray-300"
            />
            <label htmlFor="on" className="ml-2 text-sm font-medium text-gray-900">
              On
            </label>
            <input
              type="date"
              name="repeatEndDate"
              value={formData.repeatEndDate}
              onChange={handleChange}
              disabled={formData.repeatEnds !== 'On'}
              className="ml-2 bg-white border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5"
            />
          </div>
      </div>
    </fieldset>
              </div>
            </div>

          </>
        );
      default:
        return null;
    }
  };
  
  return (
      <div className="fixed inset-0 flex items-start pt-14 pb-2 justify-center bg-gray-500 bg-opacity-75 overflow-y-auto">
        <div 
          className="max-w-sm md:max-w-xl p-6 pt-5"
          style={{
            background: `linear-gradient(to bottom, #F3F4F6 ${gradientHeight}, #ffffff ${gradientHeight}, #ffffff 100%)`,
            borderBottom: '1px solid black'
          }}
        >
        <div className="flex flex-row gap-2 items-start justify-between pb-10">
            <div className='flex flex-col'>
             <span className="font-semibold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%'}}>{editMode ? 'Updade Access Code' : 'New Access Code'}</span>
             <span className="text-gray-600" >{editMode ? 'Update Access Code detail below' : 'Add your new Access Code below'} </span>
            </div>
           <img
              onClick={closeModal}
              src={close}
              alt="User"
              className="cursor-pointer mt-4 pl-10"
           />
        </div>

        {registrationStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 mb-4">
            Registration successful!
          </div>
        )}

        {registrationStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 mb-2">
            Registration failed. Please try again
          </div>
        )}

        {updateStatus === 'success' && (
          <div className="bg-green-200 text-green-800 p-2 mb-2">
            Update successful!
          </div>
        )}

        {updateStatus === 'failure' && (
          <div className="bg-red-200 text-red-800 p-2 mb-2">
            Update failed. Please try again.
          </div>
        )}

        <div className="flex flex-col md:flex-row gap-3 mb-6">
          <button
            className={`mr-2 w-full md:w-1/2 py-2 ${formMode === 'single' ? 'text-white bg-gray-800' : 'text-gray-600 bg-gray-50'}`}
            onClick={() => toggleFormMode('single')}
          >
            Single
          </button>
          <button
            className={`w-full md:w-1/2 py-2 text-white ${formMode === 'recurring' ? 'bg-gray-800' : 'bg-gray-300'}`}
            onClick={() => toggleFormMode('recurring')}
          >
            Recurring
          </button>
        </div>

          {formMode === 'single' && (
            <form>
              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-6">
                  <label className="text-sm font-medium text-gray-900">
                    Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="bookingCustomerName"
                    placeholder='None'
                    value={formData.bookingCustomerName}
                    onChange={handleChange}
                    className={`${formErrors.bookingCustomerName ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingCustomerName && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingCustomerName}</p>
                  )}
                </div>
                
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">
                    Phone 
                  </label>
                  <input
                    type="text"
                    name="bookingCustomerPhone"
                    value={formData.bookingCustomerPhone}
                    onChange={handleChange}
                    placeholder="None"
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">
                    Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="bookingCode"
                    value={formData.bookingCode}
                    onChange={handleChange}
                    placeholder="None"
                    className={` ${formErrors.bookingCode ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingCode && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingCode}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3">  
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">Start Date <span className="text-red-500">*</span></label>
                  <input
                    type="date"
                    name="bookingStartDate"
                    value={formData.bookingStartDate}
                    onChange={handleChange}
                    placeholder="Select a start date"
                    className={` ${formErrors.bookingStartDate ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingStartDate && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingStartDate}</p>
                  )}
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900" style={{color:'black'}}>Start Time <span className="text-red-500">*</span></label>
                    <input
                      type="time"
                      name='bookingStartsAtTime'
                      placeholder='None'
                      value={formData.bookingStartsAtTime}
                      onChange={handleChange}
                      className={` ${formErrors.bookingStartsAtTime ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                    required
                    />

                  {formErrors.bookingStartsAtTime && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingStartsAtTime}</p>
                  )}
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                    <label htmlFor="end-time" className='text-sm font-medium text-gray-900'>End Date <span className="text-red-500">*</span></label>
                    <input
                      type="date"
                      name="bookingEndDate"
                      value={formData.bookingEndDate}
                      onChange={handleChange}
                      className={` ${formErrors.bookingEndDate ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                    />
                    {formErrors.bookingEndDate && (
                      <p className="text-red-500 text-xs italic">{formErrors.bookingEndDate}</p>
                    )}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-2 mt-6">
                <div className="w-full md:w-1/2  px-3 mb-2">
                  <label className="text-sm font-medium text-gray-900" style={{color:'black'}}>End Time <span className="text-red-500">*</span></label>
                  <input
                    type="time"
                    name='bookingEndsAtTime'
                    placeholder='None'
                    value={formData.bookingEndsAtTime}
                    onChange={handleChange}
                    className={` ${formErrors.bookingEndsAtTime ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />

                  {formErrors.bookingEndsAtTime && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingEndsAtTime}</p>
                  )}
                </div>
                  
                <div className="w-full md:w-1/2 px-3 mb-2 md:mb-6">
                  <label className="text-sm font-medium text-gray-900">
                    Booking Email
                  </label>
                  <input
                    type="text"
                    name="bookingCompanyEmail"
                    placeholder="None"
                    value={formData.bookingCompanyEmail}
                    onChange={handleChange}
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                    />
                </div>
    
                {userData && userData.role !== 'Company User' && (
                  <div className="w-full px-3 mb-2 md:mb-0">
                      <label className="text-sm font-medium text-gray-900">
                          Company <span className="text-red-500">*</span>
                      </label>
                      <select
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                          className={`mt-1 py-2 px-2 w-full border ${formErrors.company ? 'border-red-500' : 'border-gray-300'} text-gray-500 focus:outline-none`}
                      >
                          {companies.map((company) => (
                            <option key={company.CompanyID} value={company.company}>
                              {company.company}
                            </option>
                          ))}
                      </select>
                      {formErrors.company && (
                          <p className="text-red-500 text-xs italic">{formErrors.company}</p>
                      )}
                  </div>
                )}

                <div className='p-4 flex flex-col'>
                  <label className=" text-gray-900 font-semibold pb-4">Notify user</label>
                  <div className='flex flex-row gap-2 items-center mb-2'>
                  <input
                    type="checkbox"
                    id="eConformationText"
                    checked={formData.eConformationText === 'true'}
                    onChange={handleChangeCheckbox}
                  />
                    <label htmlFor="rememberMe" className=" text-md" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                      Text message
                    </label>

                    <input
                      type="checkbox"
                      id="eConformationEmail"
                      checked={formData.eConformationEmail === 'true'}
                      onChange={handleChangeCheckbox}
                    />
                    <label htmlFor="rememberMe" className=" text-md" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                      Email
                    </label>

                    {emailError && (
                      <p className="text-red-500 text-xs italic">{emailError}</p>
                    )}

                    {showEmailPopup && (
                      <EmailPopup 
                        onClose={() => setShowEmailPopup(false)}
                        onSend={handleSendEmail}
                        setEmailSubject={setEmailSubject}
                        setEmailBody={setEmailBody}
                        emailSubject={emailSubject}
                        emailBody={emailBody}
                        setResponseMessage={setResponseMessage}
                        responseMessage={responseMessage}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                      />
                    )}
                  </div>
                </div>

              </div>
            </form>
          )}

          {formMode === 'recurring' && (
            <form>
              <div className="flex flex-wrap -mx-3 mb-2">
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-6">
                  <label className="text-sm font-medium text-gray-900">
                    Name <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="bookingCustomerName"
                    placeholder='None'
                    value={formData.bookingCustomerName}
                    onChange={handleChange}
                    className={`${formErrors.bookingCustomerName ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingCustomerName && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingCustomerName}</p>
                  )}
                </div>
                
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">
                    Phone 
                  </label>
                  <input
                    type="text"
                    name="bookingCustomerPhone"
                    value={formData.bookingCustomerPhone}
                    onChange={handleChange}
                    placeholder="None"
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {/* {formErrors.bookingCustomerPhone && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingCustomerPhone}</p>
                  )} */}
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">
                    Code <span className="text-red-500">*</span>
                  </label>
                  <input
                    type="text"
                    name="bookingCode"
                    value={formData.bookingCode}
                    onChange={handleChange}
                    placeholder="None"
                    className={` ${formErrors.bookingCode ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingCode && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingCode}</p>
                  )}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3">  
                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900">Start Date <span className="text-red-500">*</span></label>
                  <input
                    type="date"
                    name="bookingStartDate"
                    value={formData.bookingStartDate}
                    onChange={handleChange}
                    placeholder="Select a start date"
                    className={` ${formErrors.bookingStartDate ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                  {formErrors.bookingStartDate && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingStartDate}</p>
                  )}
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label className="text-sm font-medium text-gray-900" style={{color:'black'}}>Start Time <span className="text-red-500">*</span></label>
                    <input
                      type="time"
                      name='bookingStartsAtTime'
                      placeholder='None'
                      value={formData.bookingStartsAtTime}
                      onChange={handleChange}
                      className={` ${formErrors.bookingStartsAtTime ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                      required
                    />

                  {formErrors.bookingStartsAtTime && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingStartsAtTime}</p>
                  )}
                </div>

                <div className="w-full md:w-1/2 lg:w-1/3 px-3 mb-2 md:mb-0">
                  <label htmlFor="end-time" className='text-sm font-medium text-gray-900'>End Date <span className="text-red-500">*</span></label>
                  <input
                    type="date"
                    name="bookingEndDate"
                    value={formData.bookingEndDate}
                    onChange={handleChange}
                    className={` ${formErrors.bookingEndDate ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                    {formErrors.bookingEndDate && (
                      <p className="text-red-500 text-xs italic">{formErrors.bookingEndDate}</p>
                    )}
                </div>
              </div>

              <div className="flex flex-wrap -mx-3 mb-2 mt-6">
                <div className="w-full md:w-1/2  px-3 mb-2">
                  <label className="text-sm font-medium text-gray-900" style={{color:'black'}}>End Time <span className="text-red-500">*</span></label>
                  <input
                    type="time"
                    name='bookingEndsAtTime'
                    placeholder='None'
                    value={formData.bookingEndsAtTime}
                    onChange={handleChange}
                    className={` ${formErrors.bookingEndsAtTime ? 'border-red-500' : ''} mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />

                  {formErrors.bookingEndsAtTime && (
                    <p className="text-red-500 text-xs italic">{formErrors.bookingEndsAtTime}</p>
                  )}
                </div>
                  
                <div className="w-full md:w-1/2 px-3 mb-2 md:mb-6">
                  <label className="text-sm font-medium text-gray-900">
                    Booking Email
                  </label>
                  <input
                    type="text"
                    name="bookingCompanyEmail"
                    placeholder="None"
                    value={formData.bookingCompanyEmail}
                    onChange={handleChange}
                    className={`mt-1 py-2 px-2 w-full border border-gray-300 text-gray-500 focus:outline-none`}
                  />
                </div>
    
                {userData && userData.role !== 'Company User' && (
                  <div className="w-full px-3 mb-2 md:mb-0">
                      <label className="text-sm font-medium text-gray-900">
                          Company <span className="text-red-500">*</span>
                      </label>
                      <select
                          name="company"
                          value={formData.company}
                          onChange={handleChange}
                          className={`mt-1 py-2 px-2 w-full border ${formErrors.company ? 'border-red-500' : 'border-gray-300'} text-gray-500 focus:outline-none`}
                      >
                          {companies.map((company) => (
                            <option key={company.CompanyID} value={company.company}>
                              {company.company}
                            </option>
                          ))}
                      </select>
                      {formErrors.company && (
                          <p className="text-red-500 text-xs italic">{formErrors.company}</p>
                      )}
                  </div>
                )}

<div className='p-4 flex flex-col'>
                  <label className=" text-gray-900 font-semibold pb-4">Notify user</label>
                  <div className='flex flex-row gap-2 items-center mb-2'>
                  <input
                    type="checkbox"
                    id="eConformationText"
                    checked={formData.eConformationText === 'true'}
                    onChange={handleChangeCheckbox}
                  />
                    <label htmlFor="rememberMe" className=" text-md" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                      Text message
                    </label>

                    <input
                      type="checkbox"
                      id="eConformationEmail"
                      checked={formData.eConformationEmail === 'true'}
                      onChange={handleChangeCheckbox}
                    />
                    <label htmlFor="rememberMe" className=" text-md" style={{fontSize:'14px', color:'#010808', lineHeight:'20px', letterSpacing:'0%'}}>
                      Email
                    </label>

                    {emailError && (
                      <p className="text-red-500 text-xs italic">{emailError}</p>
                    )}

                    {showEmailPopup && (
                      <EmailPopup 
                        onClose={() => setShowEmailPopup(false)}
                        onSend={handleSendEmail}
                        setEmailSubject={setEmailSubject}
                        setEmailBody={setEmailBody}
                        emailSubject={emailSubject}
                        emailBody={emailBody}
                        setResponseMessage={setResponseMessage}
                        responseMessage={responseMessage}
                        errorMessage={errorMessage}
                        setErrorMessage={setErrorMessage}
                      />
                    )}
                  </div>
                </div>

              </div>

              {editMode && (
                <div className="flex flex-col mb-4">
                  <label htmlFor="isRecurring" className="text-sm font-medium text-gray-900">
                    Recurring Booking
                  </label>
                  <select
                    id="isRecurring"
                    name="isRecurring"
                    value={formData.isRecurring}
                    onChange={(e) => setFormData({ ...formData, isRecurring: e.target.value })}
                    className="mt-1 block w-full pl-3 py-2 text-gray-700 border border-gray-300 focus:outline-none sm:text-sm"
                  >
                    <option value="false">No</option>
                    <option value="true">Yes</option>
                  </select>
                </div>
              )}

            <div className="mb-2 mt-4">
              <label htmlFor="repeats" className="block text-md font-medium text-gray-700">Repeats:</label>
              <select
                id="repeats"
                name="repeats"
                value={formData.repeats}
                onChange={handleChange}
                className="mt-1 block w-full pl-3 py-2 text-gray-700 border border-gray-300 focus:outline-none sm:text-sm"
              >
                <option value="Daily">Daily</option>
                <option value="Weekly">Weekly</option>
                <option value="Monthly">Monthly</option>
                <option value="Yearly">Yearly</option>
              </select>
                {renderRepeatOptions()}
            </div>

            <div className="flex flex-wrap -mx-3 mb-2">
            </div>
          </form>
          )}

          <div className="flex md:flex-row md:justify-end flex-col gap-2 pt-6">
            <button
              onClick={closeModal}
              className="px-3 py-2 text-gray-600 bg-white border"
            >
              Discard
            </button>
            
            <button
              onClick={handleSave}
              type="submit"
              className='py-2 px-3 bg-black text-white hover:bg-teal-600 focus:outline-none focus:ring focus:border-teal-300'
              style={{ backgroundColor: '#010808' }}
            >
              {editMode ? 'Save changes' : 'Add Code'}
            </button>
         </div>
      </div>
      </div>
   );
};

export default NewBookingForm;
