const SmsLogModal = ({ isOpen, onClose, booking, storedRole,
    storedCompany }) => {
    if (!isOpen || !booking) return null;
    const shouldHideCompany = ['Company User', 'Company Admin'].includes(storedRole);

    return (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-75 flex justify-center items-center px-4">
            <div className="bg-white rounded-lg w-full max-w-4xl overflow-hidden shadow-lg">
                <div className="px-5 py-4 border-b border-gray-200 flex justify-between items-center">
                    <h2 className="text-lg font-semibold text-gray-900">SMS Delivery Log</h2>
                    <button onClick={onClose} className="text-gray-400 hover:text-gray-600">
                        <span className="text-2xl">&times;</span>
                    </button>
                </div>
                <div className="p-3">
                    <table className="w-full text-sm text-left text-gray-500">
                        <thead  className=" bg-gray-50">
                            <tr>
                                <th scope="col" className="px-6 py-3 text-gray-600">Date</th>
                                <th scope="col" className="px-6 py-3 text-gray-600">Customer</th>
                                <th scope="col" className="px-6 py-3 text-gray-600">Origin</th>
                                <th scope="col" className="px-6 py-3 text-gray-600">Phone Number</th>
                                <th scope="col" className="px-6 py-3 text-gray-600">Status</th>
                                {!shouldHideCompany && <th scope="col" className="px-6 py-3 text-gray-600">Company</th>}
                            </tr>
                        </thead>
                        <tbody>
                            <tr className="bg-white border-b hover:bg-gray-50">
                               <td className="px-6 py-4">{formatDate(booking.timestamp)}</td>
                                <div className="px-6 whitespace-normal sm:whitespace-nowrap overflow-hidden text-ellipsis">
                                    <div className="font-bold text-black pb-1 hover:underline cursor-pointer">
                                         {booking.bookingCustomerName}
                                    </div>
                                    <div className="text-sm">{booking.bookingCompanyEmail}</div>
                                </div>
                                <td className="px-6 py-4">{booking.Integration}</td>
                                <td className="px-6 py-4">{booking.bookingCustomerPhone}</td>
                                <td className="px-6 py-4">{booking.SMSsent === 'true' ? 'Delivered' : 'No SMS Sent'}</td>
                                {!shouldHideCompany && <td className="px-6 py-4">{booking.company}</td>}
                            </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    );
};

const formatDate = (dateString) => {
    const options = { year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit' };
    return new Date(dateString).toLocaleDateString('sv-SE', options).replace(',', '');
};

export default SmsLogModal;
