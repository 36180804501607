import React from 'react';
import { useState, useEffect } from 'react';
import SettingsModal from './SettingModal';
import { useSwipeable } from 'react-swipeable';
import axios from 'axios';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons';
import { CSVLink } from 'react-csv';
import CompaniesForm from './CompaniesForm';
import TableCompany from './TableCompany';
import icon from '../Assets/iconS.svg';
import iconL from '../Assets/left.svg';
import iconR from '../Assets/right.svg';
import download from '../Assets/download.svg';
import plus from '../Assets/plus.svg';
import search from '../Assets/search.svg';
import './styles.css';

const Company = ({setIsMenuOpen}) => {
  const headings = ["company_ID","company", "Website",  "ContactEmail", "ContactNumber", "Address", "RegistrationNumber"];
  const [data, setData] = useState('');
  const [filter, setFilter] = useState('');
  const [showModal, setShowModal] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10);
  const [result, setResult] = useState([]);
  const [rows, setRows] = useState([]);
  const [selectedCompanyData, setSelectedCompanyData] = useState(null);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  const [bookings, setBookings] = useState([]);
  const [userData, setUserData] = useState(null);
  
  useEffect(() => {
    const userDataFromStorage = localStorage.getItem('userData');
    if (userDataFromStorage) {
      const parsedUserData = JSON.parse(userDataFromStorage);
      setUserData(parsedUserData);
    }
  }, []);
  
  const handleSettings = (companyData) => {
    setSelectedCompanyData(companyData);
    setShowSettingsModal(true);
  };

  const saveSettings = (newSmsCount) => {
    console.log("SMS Count Saved:", newSmsCount);
  };

  useEffect(() => {
    const fetchDataByRole = async () => {
      try {
        if (userData && userData.role === 'Company Admin') {
          const response = await axios.get('https://eunitstest.onrender.com/api/getcompanydetails');
          console.log('Response status:', response.status);
          console.log('Data fetched:', response.data);
  
          let fetchedData = response.data.filter(company => company.company === userData.company);
          const seenRegistrationNumbers = new Set();
          const uniqueData = fetchedData.filter((company) => {
            if (company.RegistrationNumber && !seenRegistrationNumbers.has(company.RegistrationNumber)) {
              seenRegistrationNumbers.add(company.RegistrationNumber);
              return true;
            }
            return false;
          });
  
          const filteredData = applyFilter(uniqueData, filter, headings);
          setData(filteredData);
          setResult(filteredData);
          setRows(filteredData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchDataByRole();
  }, [userData]);  

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (userData && userData.role === 'System Admin') {
          const response = await axios.get('https://eunitstest.onrender.com/api/getcompanydetails');
          let fetchedData = response.data;
          const seenRegistrationNumbers = new Set();
          const uniqueData = fetchedData.filter((company) => {
            if (company.RegistrationNumber && !seenRegistrationNumbers.has(company.RegistrationNumber)) {
              seenRegistrationNumbers.add(company.RegistrationNumber);
              return true;
            }
            return false;
          });
  
          const filteredData = applyFilter(uniqueData, filter, headings);
          setResult(filteredData);
          setData(filteredData);
          setRows(filteredData);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
  }, [userData]);
  
  const handleDelete = async (company_ID) => {
    try {
      console.log('Dynamic companyId:', company_ID);
      const response = await axios.post('https://eunitstest.onrender.com/api/deletecompanydetails', { company_ID });

      console.log('Response status:', response.status);
      console.log('Message:', response.data.msg);

      if (response.status === 200) {
        const updatedData = data.filter((company) => company.company_ID !== company_ID);
        setData(updatedData);

        const filteredData = applyFilter(updatedData, filter, headings);
        setResult(filteredData);

        const indexOfLastItem = currentPage * itemsPerPage;
        const indexOfFirstItem = indexOfLastItem - itemsPerPage;
        const currentItems = filteredData.slice(indexOfFirstItem, indexOfLastItem);
        setRows(currentItems);
      } else {
        console.error('Error deleting company:', response.data.error);
      }
    } catch (error) {
      console.error('Error deleting company:', error);
    }
  };

  const normalizeAndReplace = (input) => {
    let normalized = input.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase();
    normalized = normalized.replace(/æ/g, 'ae').replace(/œ/g, 'oe');
    normalized = normalized.replace(/ß/g, 'ss');
    normalized = normalized.replace(/ð/g, 'd');
  
    return normalized;
  };
  
  const applyFilter = (data, filter, headings) => {
    if (filter.length >= 3) {
      const normalizedFilter = normalizeAndReplace(filter);
      const filtered = data.filter((row) => {
        return headings.some((heading) => {
          const rowValue = normalizeAndReplace(String(row[heading] || ''));
          return rowValue.includes(normalizedFilter);
        });
      });
      return filtered;
    } else {
      return data;
    }
  };
  
  const handleFilterChange = (value) => {
    setFilter(value);
    if (value.length >= 3) {
      const filteredData = applyFilter(data, value, headings);
      setRows(filteredData);
    } else {
      setRows(data);
    }
  };

  const handleNewCompany = () => {
    setSelectedCompanyData(null);
    setShowModal(true);
  };

  const closeModal = () => {
    setShowModal(false);
    setSelectedCompanyData(null);
  };

  const handleEditCompany = (companyData) => {
    setSelectedCompanyData(companyData);
    setShowModal(true);
  };

  const updateTableData = (updatedCompanyData) => {
    console.log('Updated company data:', updatedCompanyData);
    const updatedRows = rows.map(row => {
      if (row.company_ID === updatedCompanyData.company_ID) {
        return updatedCompanyData;
      }
      return row;
    });
  
    setRows(updatedRows);
  
    const updatedData = data.map(company => {
      if (company.company_ID === updatedCompanyData.company_ID) {
        return updatedCompanyData;
      }
      return company;
    });
  
    setData(updatedData);
  };  

  const updateTableDataAdd = (updatedCompanyData) => {
    const newRows = [updatedCompanyData, ...rows];
    setRows(newRows);
  }; 

  const updateCompanyData = (updatedCompanyData) => {
    console.log('Updated company data:', updatedCompanyData);
    const updatedRows = rows.map(row => {
      if (row.company_ID === updatedCompanyData.company_ID) {
        return updatedCompanyData;
      }
      return row;
    });
  
    setRows(updatedRows);

    const updatedData = data.map(company => {
      if (company.company_ID === updatedCompanyData.company_ID) {
        return { ...company, ...updatedCompanyData };
      }
      return company;
    });

    setData(updatedData);
    setSelectedCompanyData(updatedCompanyData);
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => setIsMenuOpen(false),
    onSwipedRight: () => setIsMenuOpen(true),
    preventDefaultTouchmoveEvent: true,
    trackTouch: true,
    trackMouse: false
  });

  return (
    <div className="mx-auto container md:pt-8">
      <div {...handlers} className="pr-8 pl-8">
        <div className='md:flex md:flex-row lg:flex lg:flex-row items-center gap-3 hidden' style={{color:"#010808"}}>
        <img
          src={icon}
          alt="User"
          className=""
        />
          <FontAwesomeIcon icon={faChevronRight} className="" />
          <span className='font-bold'>Companies</span>
        </div>

        <div className="bg-gray-100 text-black flex flex-col items-start lg:flex-row md:flex-row md:items-center md:justify-between">
          <div className="flex flex-col pb-8 lg:pt-0">
            <span className="font-bold" style={{fontSize:'36px', color:'#010808', lineHeight:'44px', letterSpacing:'2%',}}>Companies</span>
            <p className="text-gray-600 text-base" style={{fontSize:'16px', lineHeight:'24px', letterSpacing:'0%'}}>Below all registered companies are listed.</p>
          </div>

          <div className="flex flex-col mt-0 md:mt-4">
            <div className="flex flex-col items-start lg:flex-row lg:items-center md:flex-row md:items-center md:justify-end gap-2">
              <CSVLink
                data={data}
                headers={headings}
                filename={'companies.csv'}
                className="custom-width flex items-center justify-center  gap-2 border bg-white border-gray-300 md:px-3 py-2" style={{ color: "#010808" }}
              >
                <img
                  src={download}
                  alt="User"
                  className=""
                />
                <span className='font-bold' style={{ fontSize: '18px', lineHeight: '28px', color: '#010808', letterSpacing: '0%' }}>Export</span>
              </CSVLink>

              { userData && userData.role === 'System Admin' && (
                <button 
                  onClick={handleNewCompany} 
                  className="flex items-center justify-center gap-2 border text-white  border-gray-300 w-full md:w-auto py-2 md:px-3" 
                  style={{ backgroundColor: '#010808' }}
                >
                  <img
                    src={plus}
                    alt="User"
                    className=""
                  />
                  <span className='font-bold' style={{ fontSize: '18px', lineHeight: '28px', backgroundColor: '#010808', letterSpacing: '0%' }}>New Company</span>
                </button>
              )}
            </div>

            <div className="custom-widthl relative mt-6 md:mt-0">
              <span className="text-black hidden lg:block md:block pt-6">Search</span>
              <img
                  src={search}
                  alt="User"
                  className="absolute mt-5 pt-1 transform -translate-y-1/2 left-3 text-gray-500"
                />

              <input
                className="w-full px-10 text-gray-500 py-2 border rounded focus:outline-none"
                type="text"
                placeholder="Search by keyword"
                value={filter}
                onChange={(e) => handleFilterChange(e.target.value)}
              />
            </div>
          </div>
        </div>
      </div>

      <div className="mt-8 mr-8 ml-8 overflow-x-auto">
        <TableCompany 
          headings={headings} 
          rows={rows} 
          onDelete={handleDelete} 
          onEditCompany={handleEditCompany} 
          onSettings={handleSettings} 
        />
      </div>

      {showModal && (
        <div className="fixed inset-0 bg-opacity-75 flex items-center justify-center">
          <div className="bg-white p-8 rounded-md">
            <CompaniesForm 
              closeModal={closeModal}
              editMode={selectedCompanyData !== null}
              companyData={selectedCompanyData}
              onConnectionUpdated={updateTableData}
              updateTableDataAdd={updateTableDataAdd}
              rows={rows}
              setRows={setRows}
            />
          </div>
        </div>
      )}

      {showSettingsModal && (
        <SettingsModal
          isOpen={showSettingsModal}
          closeModal={() => setShowSettingsModal(false)}
          onSave={saveSettings}
          companyData={selectedCompanyData}
          updateCompanyData={updateCompanyData}
          userData={userData}
        />
      )}
    </div>
  );
};

export default Company;
